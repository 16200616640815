import axios from '../xhr';
// TYPES
import IVisit from '../../types/visitInterface';

export interface IRegisterResponse {
	status: number;
	message: string;
	errors?: string[];
}

const updateVisit = (visitData: IVisit | any): Promise<IRegisterResponse> => {
	const response = axios
		.patch('visit', visitData)
		.then((response) => {
			//console.log(response);
			if (response.status === 200) {
				return Promise.resolve({
					status: 200,
					message: 'Wizyta została pomyślnie zaktualizowana',
				});
			}
			if (response.status === 409) {
				return Promise.resolve({
					status: 409,
					message: response.data.message,
				});
			}
			if (response.status === 422) {
				return Promise.resolve({
					status: 422,
					message: 'Nie poprawne pola',
					errors: response.data.errors,
				});
			}

			return Promise.reject({
				status: 499,
				message: 'Nie znany błąd',
			});
		})
		.catch((e) => {
			//console.log(e);
			return Promise.reject(e);
			//   return Promise.reject({
			//     status: 500,
			//     message: 'Wewnętrzny błąd serwera',
			//   });
		});

	return response;
};

export default updateVisit;
