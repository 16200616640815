import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import rootReducer from './rootReducer';
import {routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import throttle from 'lodash/throttle';

export const history = createBrowserHistory({basename: '/public_html'});

const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('state', serializedState);
  } catch (e) {
    // Ignore write errors;
  }
};

const peristedState = loadState();

const store = createStore(
  rootReducer(history),
  peristedState,
  composeWithDevTools(applyMiddleware(routerMiddleware(history)))
);

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000)
);

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
