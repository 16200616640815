import React, {ChangeEvent, useRef, useState} from 'react';
import FormMessage from './FormMessage';
import INewUser from '../../types/NewUserInterface';
import addUser, {IRegisterResponse} from '../../adapters/auth/addUser';
import {Button, Card, Col, Form, InputGroup, Row} from 'react-bootstrap';
import {IUser} from '../../redux/user/types';
import PeselDecoder from '../../helpers/peselDecoder';
import updateUser from '../../adapters/user/updateUser';
import {SliderPicker} from 'react-color';
// MODULES
import {notificationActions} from '../../redux/notifications/index';
import {connect} from 'react-redux';

export interface IAddUserFormProps {
	user: IUser;
	title: string;
	buttonText: string;
	addNotification: any;
}

const AddUserForm = (props: IAddUserFormProps) => {
	const [state, setState] = useState<INewUser>({
		...props.user,
		password: '',
	});
	const formRef = useRef<HTMLFormElement>(null);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setState({
			...(state as INewUser),
			[event.currentTarget.name]: event.currentTarget.value,
		});

		if (event.currentTarget.name === 'pesel') {
			let peselDecoder = new PeselDecoder(event.currentTarget.value);

			if (peselDecoder.validate()) {
				event.currentTarget.setCustomValidity('');
				setState({
					...(state as INewUser),
					gender: peselDecoder.getGender(),
					birthdate: peselDecoder.getDate(),
					[event.currentTarget.name]: event.currentTarget.value,
				});
			} else {
				event.currentTarget.setCustomValidity('PESEL jest nie poprawny');
				if (formRef.current) {
					formRef.current.reportValidity();
				}
			}
		}
	};

	const handleSubmit = (e: React.FormEvent) => {
		if (state) {
			if (state.id < 0) {
				addUser(state as INewUser)
					.then((resp: IRegisterResponse) => {
						props.addNotification(resp.message);
					})
					.catch((e) => props.addNotification(e.message));
			} else {
				let userData: any = state;
				delete userData.password;

				updateUser(userData as IUser)
					.then((resp: IRegisterResponse) => {
						props.addNotification(resp.message);
					})
					.catch((e) => props.addNotification(e.message));
			}
		}
		e.preventDefault();
	};

	return (
		<Row>
			<Card>
				<Card.Header as='h5'>{props.title}</Card.Header>
				<Card.Body>
					<Form onSubmit={handleSubmit} ref={formRef}>
						<Row>
							<Col sm={6}>
								<Form.Group controlId='formBasicPassword'>
									<Form.Label className='required'>E-mail</Form.Label>
									<Form.Control
										type='email'
										placeholder='E-mail'
										autoComplete='new-email'
										name='email'
										onChange={(event) => handleChange(event as any)}
										value={state.email}
										required
									/>
								</Form.Group>
								{props.user.id < 0 && (
									<Form.Group controlId='formBasicPassword'>
										<Form.Label className='required'>Hasło</Form.Label>
										<Form.Control
											type='password'
											placeholder='Hasło'
											autoComplete='new-password'
											name='password'
											minLength={8}
											onChange={(event) => handleChange(event as any)}
											value={state.password}
											required
										/>
									</Form.Group>
								)}
								<Form.Group controlId='formBasicPassword'>
									<Form.Label className='required'>Imię</Form.Label>
									<Form.Control
										type='text'
										placeholder='Imię'
										name='name'
										onChange={(event) => handleChange(event as any)}
										value={state.name}
										required
									/>
								</Form.Group>
								<Form.Group controlId='formBasicPassword'>
									<Form.Label className='required'>Nazwisko</Form.Label>
									<Form.Control
										type='text'
										placeholder='Nazwisko'
										name='lastname'
										onChange={(event) => handleChange(event as any)}
										value={state.lastname}
										required
									/>
								</Form.Group>
								<Form.Group controlId='formBasicPassword'>
									<Form.Label className='required'>PESEL</Form.Label>
									<Form.Control
										type='text'
										placeholder='11223344556'
										name='pesel'
										pattern='\d{11}'
										minLength={11}
										maxLength={11}
										onChange={(event) => handleChange(event as any)}
										value={state.pesel}
										required
									/>
								</Form.Group>
								<Form.Group controlId='formBasicPassword'>
									<Form.Label className='required'>Ulica, nr. domu/lokalu</Form.Label>
									<Form.Control
										type='text'
										placeholder='Ulica, nr. domu/lokalu'
										name='street'
										onChange={(event) => handleChange(event as any)}
										value={state.street}
										required
									/>
								</Form.Group>
								<Form.Group controlId='formBasicPassword'>
									<Form.Label className='required'>Kod pocztowy</Form.Label>
									<Form.Control
										type='text'
										placeholder='12-123'
										name='postal_code'
										pattern='\d{2}[-]\d{3}'
										data-inputmask="'mask': ''"
										onChange={(event) => handleChange(event as any)}
										value={state.postal_code}
										required
									/>
								</Form.Group>
								<Form.Group controlId='formBasicPassword'>
									<Form.Label className='required'>Miasto</Form.Label>
									<Form.Control
										type='text'
										placeholder='Miasto'
										name='city'
										onChange={(event) => handleChange(event as any)}
										value={state.city}
										required
									/>
								</Form.Group>
								<Form.Group controlId='formBasicPassword'>
									<Form.Label className='required'>Numer telefonu</Form.Label>
									<Form.Control
										type='tel'
										placeholder='+48 444 000 555'
										name='phone_number'
										onChange={(event) => handleChange(event as any)}
										value={state.phone_number}
										pattern='^(([+]\d{1,2})\d{9})|(\d{9})'
										required
									/>
								</Form.Group>
							</Col>
							<Col sm={6}>
								<Form.Group className='py-2'>
									<Form.Label className='required'>Płeć</Form.Label>
									<Form.Check
										type='radio'
										label={`mężczyzna`}
										name='gender'
										value='male'
										onChange={(event) => handleChange(event as any)}
										checked={state.gender === 'male'}
									/>
									<Form.Check
										type='radio'
										label={`kobieta`}
										name='gender'
										value='female'
										onChange={(event) => handleChange(event as any)}
										checked={state.gender === 'female'}
									/>
								</Form.Group>
								<Form.Group controlId='formBasicPassword'>
									<Form.Label className='required'>Data urodzenia</Form.Label>
									<Form.Control
										type='date'
										name='birthdate'
										onChange={(event) => handleChange(event as any)}
										value={state.birthdate}
										required
									/>
								</Form.Group>
								<Form.Group>
									<Form.Label className='required'>Rola</Form.Label>
									<Form.Control
										as='select'
										name='permission'
										onChange={(event) => handleChange(event as any)}
										value={state.permission}
										required
									>
										<option value='fizjo'>Fizjoterapeuta</option>
										<option value='recepcja'>Recepcja</option>
										<option value='admin'>Administrator</option>
									</Form.Control>
								</Form.Group>
								<Form.Group controlId='exampleForm.ControlTextarea1'>
									<Form.Label>Opis</Form.Label>
									<Form.Control
										as='textarea'
										rows={5}
										name='description'
										onChange={(event) => handleChange(event as any)}
										value={state.description}
									/>
								</Form.Group>
								<Form.Label className='mt-5'>Kolor</Form.Label>
								<SliderPicker
									onChange={(e) => {
										setState({...state, additional: {color: e.hex}});
									}}
									color={state.additional?.color}
									className='mt-1'
								/>
							</Col>
							<Col sm={12}>
								<div className='w-100 d-flex justify-content-end'>
									<Button variant='success' type='submit' className='mt-4'>
										{props.buttonText}
									</Button>
								</div>
							</Col>
						</Row>
					</Form>
				</Card.Body>
			</Card>
		</Row>
	);
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addNotification: (payload: string) => dispatch(notificationActions.add(payload)),
	};
};

export default connect(null, mapDispatchToProps)(AddUserForm);
