import axios from '../xhr';
import IVisitInterface from '../../types/visitInterface';
import IVisit from '../../types/visitInterface';

export interface IResponse {
  status: number;
  message: string;
}

const visits = (dates: string[], user_id: number): Promise<IResponse | any> => {
  const response = axios
    .post(`calendar`, {dates: dates, user_id: user_id})
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data.data);
      } else if (response.status === 401) {
        return Promise.reject({
          status: 401,
          message: response.data.message,
        });
      } else {
        throw new Error('Troubles');
      }
    })
    .catch((e) => {
      return Promise.reject({
        status: e.status,
        message: e.message,
      });
    });

  return response;
};

export default visits;
