import React, {useEffect, useState} from 'react';
import {Row, Col, Accordion, Card, Button} from 'react-bootstrap';
import visits from '../../adapters/visit/getPlanedVisitsByPatient';
import IVisit from '../../types/visitInterface';
import FullVisit from '../FullVisit/FullVisit';

export interface IVisitListProps {
	patientId: number;
}

const PlanedVisitsList = (props: IVisitListProps) => {
	const [visitsData, setVisitsData] = useState<IVisit[] | undefined>();

	useEffect(() => {
		if (visitsData === undefined) {
			visits(props.patientId)
				.then((response) => {
					setVisitsData(response as IVisit[]);
				})
				.catch((e) => {
					//console.log(e);
				});
		}

		return () => {};
	}, []);

	if (visitsData && visitsData.length > 0) {
		return (
			<Row>
				<Col>
					<Accordion defaultActiveKey='0'>
						<Card key={'-12'} className='my-2'>
							<div className='w-100 d-flex justify-content-between align-items-center px-3 py-2'>
								<span>
									<b>Wizyty zaplanowane (bez przypisanego leczenia)</b>
								</span>
								<Accordion.Toggle as={Button} eventKey={'-123'}>
									Więcej
								</Accordion.Toggle>
							</div>
							<Accordion.Collapse eventKey={'-123'}>
								<Card.Body className='px-5'>
									<Row>
										<Col>
											<Accordion defaultActiveKey='0'>
												{visitsData &&
													visitsData?.map((visit) => (
														<Card key={visit.id} className='my-2'>
															<div className='w-100 d-flex justify-content-between align-items-center px-3 py-2'>
																<span>
																	<b>Wizyta:</b> {visit.date} {visit.start_time}{' '}
																	<b>Status:</b>{' '}
																	{visit.done
																		? 'Odbyta'
																		: visit.confirmed
																		? 'Potwierdzona'
																		: 'Zaplanowana'}
																</span>
																<Accordion.Toggle
																	as={Button}
																	eventKey={visit.id ? visit.id.toString() : ''}
																>
																	Więcej
																</Accordion.Toggle>
															</div>
															<Accordion.Collapse
																eventKey={visit.id ? visit.id.toString() : ''}
															>
																<Card.Body className='px-5'>
																	{visit.id && (
																		<FullVisit
																			visitId={visit.id as number}
																			start={visit.start_time}
																			date={new Date(visit.date)}
																			userId={visit.user_id}
																			duration={visit.visit_duration}
																			userPermissions='admin'
																		/>
																	)}
																</Card.Body>
															</Accordion.Collapse>
														</Card>
													))}
											</Accordion>
										</Col>
									</Row>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
					</Accordion>
				</Col>
			</Row>
		);
	} else {
		return null;
	}
};

export default PlanedVisitsList;
