import axios from '../xhr';

export interface IRegisterResponse {
  status: number;
  message: string;
  errors?: string[];
}

const changePassword = (data: {
  id: number;
  password: string;
}): Promise<IRegisterResponse> => {
  const response = axios
    .post('/changePassword', data)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve({
          status: 200,
          message: 'Hasło zostało zmienione',
        });
      }
      if (response.status === 405) {
        return Promise.resolve({
          status: 405,
          message: response.data.message,
        });
      }

      return Promise.reject({
        status: 499,
        message: 'Nie znany błąd',
      });
    })
    .catch((e) => {
      return Promise.reject({
        status: 500,
        message: 'Wewnętrzny błąd serwera',
      });
    });

  return response;
};

export default changePassword;
