import axios from '../xhr';
import { IUser } from '../../redux/user/types';

export interface IRegisterResponse {
  status: number;
  message: string;
  errors?: string[];
}

const updateUser = (userData: IUser): Promise<IRegisterResponse> => {
  const response = axios
    .post('/updateUser', userData)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve({
          status: 200,
          message: 'Dane użytkownika zostały zaktualizowane',
        });
      }
      if (response.status === 405) {
        return Promise.resolve({
          status: 405,
          message: response.data.message,
        });
      }

      return Promise.reject({
        status: 499,
        message: 'Nie znany błąd',
      });
    })
    .catch((e) => {
      return Promise.reject({
        status: 500,
        message: 'Wewnętrzny błąd serwera',
      });
    });

  return response;
};

export default updateUser;
