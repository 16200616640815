import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import logout from '../../adapters/auth/logout';
// TYPES
import {IUser} from '../../redux/user/types';
import {INotification} from '../../redux/notifications/types';
// STYELS
import './Statusbar.scss';
import {notificationActions} from '../../redux/notifications';
import {Link} from 'react-router-dom';

export interface IStatusbarProps {
  user: IUser;
  push: any;
  notifications: INotification[];
  changeNotification: any;
}

const Statusbar = (props: IStatusbarProps) => {
  const [currentNotification, setCurrentNotification] = useState<INotification | undefined>(undefined);

  useEffect(() => {
    let notif: INotification | undefined = undefined;

    props.notifications.forEach((notification) => {
      if (notification.isNew) {
        notif = notification;
      }
    });

    setCurrentNotification(notif);

    if (notif) {
      setTimeout(() => {
        props.changeNotification({...notif, isNew: false});
      }, 6000);
    }

    return () => {};
  }, [props.notifications]);

  const handleLogout = () => {
    logout();
    props.push('/login');
  };

  const handleRedirect = (path: string): void => {
    props.push(path);
  };

  const msToTimeString = (ms: number): string => {
    return new Date(ms).toTimeString().substr(0, 5);
  };

  return (
    <div className='statusbar d-flex justify-content-end align-items-center'>
      <div className='statusbar__message'>
        <div className={`message__show ${currentNotification ? '' : 'hidden'}`}>
          {currentNotification && currentNotification.message}
        </div>
      </div>
      <button className='statusbar__notification'>
        <i className='bi bi-bell-fill'></i>
        <div className='notification__box'>
          {props.notifications.map((notification) => (
            <div className='box__item'>
              <span className='item__time'>{msToTimeString(notification.time)}</span>
              <span>{notification.message}</span>
            </div>
          ))}
        </div>
      </button>
      <button className='statusbar__user'>
        {props.user.lastname.length > 0 ? props.user.lastname[0] : ''}
        {props.user.name.length > 0 ? props.user.name[0] : ''}
        <div className='user__nav'>
          <div className='user__info'>
            <div>{props.user.email}</div>
            {props.user.lastname} {props.user.name}
          </div>
          <Link className='' aria-current='page' to='/profile'>
            Mój profil
          </Link>
          <button className='' aria-current='page' onClick={() => handleLogout()} name='/logout'>
            Wyloguj
          </button>
        </div>
      </button>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user,
  notifications: state.notifications,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    changeNotification: (payload: INotification) => dispatch(notificationActions.change(payload)),
    push: (path: string) => push(path),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Statusbar);
