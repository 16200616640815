import {ICode, INewCode} from '../../types/codeInterface';
import axios from '../xhr';

export interface IAddCodeResponse {
  status: number;
  message: string;
  code?: ICode;
  errors?: string[];
}

const addCode = (codeData: INewCode): Promise<IAddCodeResponse> => {
  const response = axios
    .post('/codes', codeData)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve({
          status: 200,
          message: 'Kod został pomyślnie dodany',
          code: response.data.code,
        });
      }
      if (response.status === 409) {
        return Promise.resolve({
          status: 409,
          message: response.data.message,
        });
      }
      if (response.status === 422) {
        return Promise.resolve({
          status: 422,
          message: 'Nie poprawne pola',
          errors: response.data.errors,
        });
      }

      return Promise.reject({
        status: 499,
        message: 'Nie znany błąd',
      });
    })
    .catch((e) => {
      return Promise.reject({
        status: 500,
        message: 'Wewnętrzny błąd serwera',
      });
    });

  return response;
};

export default addCode;
