import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {notificationActions} from '../../redux/notifications/index';
import {v4 as uuid} from 'uuid';
import {BASE_URL} from '../../adapters/xhr';
// STYLES
import './AddFile.scss';

export interface IAddFileProps {
	isAgreement: boolean;
	patientId: number;
	addNotification: any;
	onAdd: any;
}

const AddFile = (props: IAddFileProps) => {
	const [fileSelected, setFileSelected] = useState<Blob>();
	const [id, setId] = useState(uuid());
	const [text, setText] = useState('');

	useEffect(() => {
		if (fileSelected) {
			try {
				setText((fileSelected as File).name);
			} catch (err) {}
		} else {
			setText('');
		}
	}, [fileSelected]);

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const fileList = e.target.files;

		if (!fileList) return;

		setFileSelected(fileList[0]);
	};

	const handleUpload = () => {
		//console.log(fileSelected);
		if (sessionStorage.getItem('token') !== null && fileSelected) {
			let data = new FormData();
			data.append('is_agreement', props.isAgreement ? '1' : '0');
			data.append('patient_id', props.patientId.toString());
			data.append('file', fileSelected);

			const fetchHeaders = new Headers();
			fetchHeaders.set('Authorization', `Bearer ${sessionStorage.getItem('token')}`);

			fetch(`${BASE_URL}images`, {
				method: 'POST',
				headers: fetchHeaders,
				body: data,
				// mode: 'no-cors',
			})
				.then((response) => response.json())
				.then((result) => {
					if (result.message === 'Success') {
						props.addNotification('Plik został pomyślnie dodany.');
						setFileSelected(undefined);
						props.onAdd(result.file);
					}
				})
				.catch(() => {
					props.addNotification('Coś poszło nie tak, plik nie został dodany.');
				});
		}
	};

	return (
		<div className='addfile w-100 d-flex justify-content-center flex-wrap py-4'>
			<label htmlFor={id}>
				<i className='bi bi-image-fill'></i> Wybierz plik
				<span>{text}</span>
				<input accept='image/*' id={id} name='photo' type='file' multiple={false} onChange={handleFileChange} />
			</label>
			<button onClick={handleUpload}>
				<i className='bi bi-cloud-arrow-up-fill'></i> Dodaj plik
			</button>
		</div>
	);
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addNotification: (payload: string) => dispatch(notificationActions.add(payload)),
	};
};

export default connect(null, mapDispatchToProps)(AddFile);
