import React, {useEffect, useState} from 'react';
import {notificationActions} from '../../redux/notifications/index';
import {connect} from 'react-redux';
import updateTreatment from '../../adapters/treatment/updateTreatment';
import getTreatmentById from '../../adapters/treatment/getTreatmentsById';
import codes from '../../adapters/code/getCodes';
import visits from '../../adapters/visit/getVisitsByTreatment';
// COMPONENTS
import Select from 'react-select';
import {Button, Card, Form} from 'react-bootstrap';
// TYPES
import {ITreatment} from '../../types/treatmentInterface';
import {ICode} from '../../types/codeInterface';
import Human, {IPoint} from '../Human/Human';
import IVisit, {IFullVisit} from '../../types/visitInterface';
import TreatmentReport from '../TreatmentReport/TreatmentReport';
import {PDFDownloadLink} from '@react-pdf/renderer';
import IPatient from '../../types/patientInterface';
// import treatmentRaport, {ITreatmentRaport} from '../../adapters/raports/treatment';

export interface ITreatmentFormProps {
	addNotification: any;
	treatment: ITreatment;
	notFetch?: boolean;
	onUpdate?: any;
}

const TreatmentForm = (props: ITreatmentFormProps) => {
	const [treatment, setTreatment] = useState<ITreatment>(props.treatment);
	const [icdCodes, setIcdCodes] = useState<ICode[]>(new Array<ICode>());
	const [icfCodes, setIcfCodes] = useState<ICode[]>(new Array<ICode>());
	const [nfzCodes, setNfzCodes] = useState<ICode[]>(new Array<ICode>());
	const [isHumanActive, setIsHumanActive] = useState<boolean>(false);
	const [humanChanged, setHumanChanged] = useState<boolean>(false);
	// const [raportData, setRaportData] = useState<ITreatmentRaport | null>(null);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTreatment({
			...treatment,
			[event.currentTarget.name]: event.currentTarget.value,
		});
	};

	useEffect(() => {
		if (!props.notFetch) {
			getTreatmentById(props.treatment.id)
				.then((result) => {
					setTreatment(result as ITreatment);
				})
				.catch((e) => {
					//console.log(e);
				});
		}

		codes()
			.then((res) => {
				const result = res as {nfz: ICode[]; icd: ICode[]; icf: ICode[]};

				setIcdCodes(result.icd ? result.icd : []);
				setIcfCodes(result.icf ? result.icf : []);
				setNfzCodes(result.nfz ? result.nfz : []);
			})
			.catch((err) => console.log(err));

		return () => {};
	}, []);

	useEffect(() => {
		if (isHumanActive) setHumanChanged(true);

		if (!isHumanActive && humanChanged) update();
	}, [isHumanActive]);

	// const handleRaport = () => {
	// 	// setRaportData(null);
	// 	//
	// 	treatmentRaport(props.treatment.id).then((data) => {
	// 		setRaportData(data as ITreatmentRaport);
	// 		// console.log(data);
	// 	});
	// };

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();

		update();
	};

	const update = () => {
		updateTreatment(treatment)
			.then((response) => {
				props.addNotification(response.message);

				if (response.treatment) {
					if (props.onUpdate) {
						props.onUpdate(props.treatment);
					}
				}
			})
			.catch((e) => {
				props.addNotification(e.message);
			});
	};

	const handleClose = (e: React.FormEvent) => {
		e.preventDefault();

		updateTreatment({...treatment, status: true} as ITreatment)
			.then((response) => {
				props.addNotification(response.message);

				if (response.treatment) {
					if (props.onUpdate) {
						//console.log(response.treatment);
						props.onUpdate(props.treatment);
					}
				}
			})
			.catch((e) => {
				props.addNotification(e.message);
			});
	};

	const prepareOptions = (codes: ICode[]): {value: number; label: string}[] => {
		return codes.map((code) => {
			return {
				value: code.id,
				label: `${code.code} ${code.desc}`,
				clearableValue: true,
			};
		});
	};

	const handlePointsChange = (points: IPoint[]) => {
		setTreatment({...treatment, points: points});
		//console.log(treatment.points);
	};

	// const handleRaport = () => {};

	return (
		<Card className='px-0 w-100'>
			<Card.Header>
				<b>Leczenie:</b> {treatment.name}
			</Card.Header>
			<Card.Body>
				<button
					className='btn btn-primary mx-3 mb-3'
					type='button'
					onClick={() => {
						setIsHumanActive(!isHumanActive);
					}}
				>
					{isHumanActive ? 'Ukryj' : 'Pokaż'} graficzne informacje o leczeniu
				</button>
				{isHumanActive && (
					<Human points={treatment.points ? treatment.points : []} onChange={handlePointsChange} />
				)}

				<Form className='d-flex flex-wrap' onSubmit={handleSubmit}>
					<Form.Group controlId='exampleForm.ControlTextarea1' className='w-50 px-3 mb-3'>
						<Form.Label className='required'>Nazwa</Form.Label>
						<Form.Control type='text' name='name' value={treatment.name} onChange={handleChange} required />
					</Form.Group>
					<Form.Group controlId='exampleForm.ControlTextarea1' className='w-50 px-3 mb-3'>
						<Form.Label>Kod ICD</Form.Label>
						<Select
							name='icd'
							options={prepareOptions(icdCodes)}
							isSearchable={true}
							value={
								treatment.icd
									? {value: treatment.icd.id, label: `${treatment.icd.code} ${treatment.icd.desc}`}
									: {value: '', label: ''}
							}
							onChange={(value) => {
								if (value?.value)
									setTreatment({
										...treatment,
										icd: icdCodes.filter((code) => code.id === Number(value?.value))[0],
									});
							}}
							clearable={true}
						/>
					</Form.Group>

					<Form.Group controlId='exampleForm.ControlTextarea1' className='w-50 px-3 mb-3'>
						<Form.Label>Kod ICF</Form.Label>
						{/* <Form.Control type='text' name='icf' value={treatment.icf} onChange={handleChange} /> */}
						<Select
							name='icf'
							options={prepareOptions(icfCodes)}
							isSearchable={true}
							closeMenuOnSelect={false}
							value={
								treatment.icf
									? treatment.icf.map((code) => {
											if (code) {
												return {value: code.id, label: `${code.code} ${code.desc}`};
											} else {
												return {value: '', label: ``};
											}
									  })
									: [{value: -1, label: ''}]
							}
							onChange={(value) => {
								if (value)
									setTreatment({
										...treatment,
										icf: icfCodes.filter((code) =>
											value.map((value) => value.value).includes(code.id)
										),
									});
							}}
							clearable={true}
							isMulti
						/>
					</Form.Group>
					<Form.Group controlId='exampleForm.ControlTextarea1' className='w-50 px-3 mb-3'>
						<Form.Label>Procedury NFZ</Form.Label>
						{/* <Form.Control type='text' name='icf' value={treatment.icf} onChange={handleChange} /> */}
						<Select
							name='nfz'
							options={prepareOptions(nfzCodes)}
							isSearchable={true}
							closeMenuOnSelect={false}
							value={
								treatment.nfz
									? treatment.nfz.map((code) => {
											return {value: code.id, label: `${code.code} ${code.desc}`};
									  })
									: [{value: -1, label: ''}]
							}
							onChange={(value) => {
								if (value)
									setTreatment({
										...treatment,
										nfz: nfzCodes.filter((code) =>
											value.map((value) => value.value).includes(code.id)
										),
									});
							}}
							clearable={true}
							isMulti
						/>
					</Form.Group>
					<Form.Group controlId='exampleForm.ControlTextarea1' className='w-50 px-3 mb-3'>
						<Form.Label>Aspekt psychologiczny</Form.Label>
						<Form.Control
							as='textarea'
							rows={4}
							name='aspect'
							onChange={handleChange}
							value={treatment.aspect || ''}
						/>
					</Form.Group>
					<Form.Group controlId='exampleForm.ControlTextarea1' className='w-50 px-3 mb-3'>
						<Form.Label>Choroby towarzyszące</Form.Label>
						<Form.Control
							as='textarea'
							rows={4}
							name='diseases'
							onChange={handleChange}
							value={treatment.diseases || ''}
						/>
					</Form.Group>
					<Form.Group controlId='exampleForm.ControlTextarea1' className='w-50 px-3 mb-3'>
						<Form.Label>Przebyte operacje</Form.Label>
						<Form.Control
							as='textarea'
							rows={4}
							name='surgeries'
							onChange={handleChange}
							value={treatment.surgeries || ''}
						/>
					</Form.Group>
					<Form.Group controlId='exampleForm.ControlTextarea1' className='w-50 px-3 mb-3'>
						<Form.Label>Leczenie</Form.Label>
						<Form.Control
							as='textarea'
							rows={4}
							name='treatment'
							onChange={handleChange}
							value={treatment.treatment || ''}
						/>
					</Form.Group>
					<div className='d-flex w-100 justify-content-end align-items-end'>
						{/* {raportData && (
							<PDFDownloadLink
								className='btn btn-primary d-block'
								document={<TreatmentReport data={raportData} />}
							>
								{({blob, url, loading, error}) => (loading ? 'Loading document...' : 'Download now!')}
								Pobierz wydruk PDF
							</PDFDownloadLink>
						)}
						<Button type='button' onClick={handleRaport} variant='success' className='mt-5 mx-3'>
							Generuj raport
						</Button> */}
						<Button onClick={handleClose} variant='warning' className='mt-5 mx-3'>
							Zamknij leczenie
						</Button>
						<Button type='submit' variant='success' className='mt-5 ml-4'>
							Zaktualizuj leczenie
						</Button>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addNotification: (payload: string) => dispatch(notificationActions.add(payload)),
	};
};

export default connect(null, mapDispatchToProps)(TreatmentForm);
