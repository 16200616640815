import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import tokenReducer from './token/reducers';
import userReducer from './user/reducers';
import dateReducer from './calendarDate/reducers';
import notificationReducer from './notifications/index';

const rootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    token: tokenReducer,
    user: userReducer,
    calendarDate: dateReducer,
    notifications: notificationReducer,
  });

export default rootReducer;
