import React from 'react';

const FormMessage = (props: { message: string; errors: any }) => {
  const parseErrors = () => {
    let errorslist = Array<JSX.Element>();

    for (const [key, value] of Object.entries(props.errors)) {
      errorslist.push(<li>{props.errors[key].join(' ')}</li>);
    }
    return errorslist;
  };

  const list2 = [{ sdas: 'asdas' }, { zet: 'dasdas' }, { err: 'dasdas' }].map(
    (err, i, st) => <li>{Object.keys(err).join(' ')}</li>
  );

  return (
    <div className='position-fixed top-0 left-0 w-50'>
      <h3>{props.message}</h3>
      <ul>{parseErrors()}</ul>
    </div>
  );
};

export default FormMessage;
