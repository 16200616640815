import IVisit from '../visitInterface';

const defaultVisit: IVisit = {
  patient_id: -1,
  user_id: -1,
  date: '2021-08-02',
  start_time: '07:00',
  visit_duration: 45,
  description: '',
  confirmed: false,
  done: false,
  price: 0,
  consultations: false,
};

export default defaultVisit;
