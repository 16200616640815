import React, {useEffect, useRef, useState} from 'react';
import humanImg from '../../assets/images/human.jpg';
import {v1 as uuid} from 'uuid';
import './Human.scss';

export interface IHumanProps {
	points: IPoint[];
	onChange: any;
}

export interface IPoint {
	x: number;
	y: number;
	id: string;
}

const Human = (props: IHumanProps) => {
	const [pointsList, setPointsList] = useState<IPoint[]>(props.points);
	const image = useRef<HTMLImageElement>(null);

	useEffect(() => {
		return () => {};
	}, [pointsList]);

	const handleClick = (e: React.MouseEvent) => {
		if (image.current) {
			const newList = [...pointsList];
			newList.push({
				x: (e.nativeEvent.offsetX / image.current.offsetWidth) * 100,
				y: (e.nativeEvent.offsetY / image.current.offsetHeight) * 100,
				id: uuid(),
			});

			setPointsList(newList);
			props.onChange(newList);
		}
	};

	const handlePointClick = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();

		const id = e.currentTarget.getAttribute('data-id');
		const newList = pointsList.filter((point) => point.id !== id);

		setPointsList(newList);
		props.onChange(newList);
	};

	return (
		<div className='human'>
			<div className='human__wrapper'>
				<img ref={image} className='wrapper__image' src={humanImg} onClick={handleClick} />
				{pointsList.map((point) => (
					<button
						style={{left: `${point.x}%`, top: `${point.y}%`}}
						className='wrapper__button'
						data-id={point.id}
						onClick={handlePointClick}
					></button>
				))}
			</div>
		</div>
	);
};

export default Human;
