const ADD = 'ADD_MESSAGE';
const RESET = 'RESET_MESSAGE';
const CHANGE = 'CHANGE_MESSAGE';

export interface INotification {
  message: string;
  isNew: boolean;
  time: number;
}

const types = {
  ADD,
  RESET,
  CHANGE,
};

export default types;
