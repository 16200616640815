import {ISchedule} from '../SheduleInterface';

const getDateString = (): string => {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};

const getDefault = (userId?: number): ISchedule[] => {
  let schedules = new Array<ISchedule>();
  const dateString = getDateString();
  const id = userId ? userId : -1;

  for (let i = 0; i < 6; i++) {
    schedules.push({
      user_id: id,
      day: i,
      start_date: dateString,
      end_date: dateString,
      start_time: '07:00',
      end_time: '15:00',
      visit_duration: 50,
      avaliable: false,
    });
  }

  return schedules;
};

export default getDefault;
