import axios from '../xhr';
import {ICode} from '../../types/codeInterface';

export interface IResponse {
	status: number;
	message: string;
}

export enum CodeType {
	ICD = 'icd',
	ICF = 'icf',
	NFZ = 'nfz',
}

const codes = (): Promise<IResponse | {nfz: ICode[]; icd: ICode[]; icf: ICode[]}> => {
	const response = axios
		.get(`/codes`)
		.then((response) => {
			if (response.status === 200) {
				return Promise.resolve({nfz: response.data.nfz, icd: response.data.icd, icf: response.data.icf});
			} else if (response.status === 401) {
				return Promise.reject({
					status: 401,
					message: response.data.message,
				});
			} else {
				throw new Error('Troubles');
			}
		})
		.catch((e) => {
			//console.log(e);
			return Promise.reject({
				status: e.status,
				message: e.message,
			});
		});

	return response;
};

export default codes;
