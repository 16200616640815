import React, {useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
// TYPES
import {IUser} from '../../redux/user/types';
import calendarDateTypes from '../../redux/calendarDate/types';
// MODULES
import MyCalendar from '../MyCalendar/MyCalendar';
import getUsers from '../../adapters/user/users';
import {connect} from 'react-redux';
// COMPONENTS
import Select from 'react-select';

const Calendars = (props: any) => {
	const [users, setUsers] = useState<IUser[]>(new Array());
	const [selectedUser, setSelectedUser] = useState<IUser>(
		props.user.permission === 'recepcja' ? undefined : props.user
	);
	const [isSingleUser, setIsSingleUser] = useState<boolean>(true);

	useEffect(() => {
		getUsers()
			.then((response) => {
				setUsers(response as IUser[]);
			})
			.catch(() => {});

		return () => {};
	}, []);

	const handleChange = (id: number) => {
		if (users) {
			const selected = users.filter((user) => user.id === id);

			if (selected.length > 0) {
				setSelectedUser(selected[0]);
			}
		}
	};

	const getSelectCurrentValue = () => {
		if (selectedUser) {
			return {
				value: selectedUser.id,
				label: `${selectedUser.lastname} ${selectedUser.name} ${selectedUser.email}`,
			};
		}
	};

	const handleWeekChange = (isForeward: boolean) => {
		const date = new Date(props.calendarDate.date);

		if (isForeward) {
			const nextWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
			props.setCalendarDate(nextWeek);
		} else {
			const lastWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
			props.setCalendarDate(lastWeek);
		}
	};

	const handleViewChange = () => {
		setIsSingleUser(!isSingleUser);
	};
	//console.log(users);
	return (
		<div className='w-100'>
			<button className='btn btn-primary mb-5' onClick={handleViewChange}>
				{isSingleUser ? 'Pojedynczy dzień dla wszystkich' : 'Tydzień dla wybranego fizjoterapeuty'}
			</button>
			{isSingleUser && (
				<Form.Group className='mb-5'>
					<Form.Label>Wybierz fizjoterapętę</Form.Label>
					<Select
						className='over__clendar'
						options={users
							?.filter((user) => user.permission !== 'recepcja')
							.map((user) => {
								return {value: user.id, label: `${user.lastname} ${user.name}  ${user.email}`};
							})}
						value={getSelectCurrentValue()}
						onChange={(value) => {
							if (value?.value) handleChange(Number(value.value));
						}}
						isSearchable
					/>
				</Form.Group>
			)}
			{isSingleUser && selectedUser && <MyCalendar user={selectedUser} />}

			{!isSingleUser && (
				<>
					<div className='w-100 d-flex justify-content-between'>
						<button onClick={() => handleWeekChange(false)} className='arrow-button'>
							<i className='bi bi-arrow-left-circle-fill'></i>
						</button>
						<button onClick={() => handleWeekChange(true)} className='arrow-button'>
							<i className='bi bi-arrow-right-circle-fill'></i>
						</button>
					</div>
					<div className='d-flex overflowx-scroll'>
						{users.map((user, index) => {
							//console.log(index);
							if (user.permission !== 'recepcja')
								return (
									<MyCalendar
										user={user}
										isSingleDay={true}
										key={user.id}
										hideHours={Boolean(index !== 0)}
									/>
								);
						})}
					</div>
				</>
			)}
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	calendarDate: state.calendarDate,
});

const mapDispatchToProps = (dispatch: any) => {
	return {
		setCalendarDate: (payload: Date) => dispatch({type: calendarDateTypes.SET, date: payload}),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendars);
