import types, { IToken } from './types';
import { AnyAction } from 'redux';

const initialState: IToken = {
  token: null,
};

const tokenReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.SET:
      return {
        ...state,
        token: action.token,
      };
    case types.RESET:
      return {
        ...state,
        token: null,
      };
    default:
      return state;
  }
};

export default tokenReducer;
