// Modules
import { setToken, deleteToken } from '../../helpers/sessionHelper';
import axios from '../xhr';
import store from '../../redux/store';
import { tokenActions } from '../../redux/token/index';

export interface ILoginData {
  email: string;
  password: string;
}

export interface ILoginResponse {
  status: number;
  message: string;
}

const login = (loginData: ILoginData): Promise<ILoginResponse> => {
  const response: Promise<ILoginResponse> = axios
    .post(
      '/login',
      loginData
    )
    .then((response) => {
      if (response.status === 200) {
        setToken(response.data.message);
        store.dispatch(tokenActions.set(response.data.message as string));

        return Promise.resolve({
          status: 200,
          message: response.data.message as string,
        });
      } else if (response.status === 401) {
        deleteToken();
        store.dispatch(tokenActions.reset());

        return Promise.reject({
          status: 401,
          message: response.data.message,
        });
      } else {
        throw new Error('Nie znany błąd, skontaktuj się z obsługą serwisu.');
      }
    })
    .catch((e) => {
      deleteToken();

      return Promise.reject({
        status: e.status,
        message: e.message,
      });
    });

  return response;
};

export default login;
