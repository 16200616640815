import React, {useEffect, useState} from 'react';
import {BASE_URL} from '../../adapters/xhr';
// COMPONENTS
import Image from '../Image/Image';

export interface IGalleryProps {
  patientId: number;
  isAgreement: boolean;
  newImage: any;
}

const Gallery = (props: IGalleryProps) => {
  const [images, setImages] = useState(new Array());

  useEffect(() => {
    if (sessionStorage.getItem('token') !== null) {
      const url = props.isAgreement
        ? `${BASE_URL}patient/agreements/${props.patientId}`
        : `${BASE_URL}patient/images/${props.patientId}`;

      const fetchHeaders = new Headers();
      fetchHeaders.set('Authorization', `Bearer ${sessionStorage.getItem('token')}`);
      fetch(url, {method: 'GET', headers: fetchHeaders})
        .then((res) => res.json())
        .then((result) => {
          setImages(result);
        });
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (props.newImage) {
      setImages([...images, {...props.newImage}]);
    }
  }, [props.newImage]);

  const handleDelete = (id: number) => {
    if (sessionStorage.getItem('token') !== null) {
      const fetchHeaders = new Headers();
      fetchHeaders.set('Authorization', `Bearer ${sessionStorage.getItem('token')}`);
      fetch(`${BASE_URL}images/${id}`, {method: 'DELETE', headers: fetchHeaders})
        .then((res) => res.json())
        .then((result) => {
          setImages(images.filter((image) => image.id !== id));
        });
    }
  };

  return (
    <div className='w-100 d-flex flex-wrap'>
      {images.map((image) => (
        <Image id={image.id} src={`${image.patient_id}/${image.name}`} key={image.id} delete={handleDelete} />
      ))}
    </div>
  );
};

export default Gallery;
