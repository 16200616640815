const SET = 'SET_TOKEN';
const RESET = 'RESET_TOKEN';

export interface IToken {
  token: string | null;
}

const types = {
  SET,
  RESET,
};

export default types;
