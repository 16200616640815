import React, {useEffect, useState} from 'react';
import patientsTreatments from '../../adapters/treatment/getTreatmentsByPatient';
import addTreatment from '../../adapters/treatment/addTreatment';
import {notificationActions} from '../../redux/notifications/index';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import updateVisit from '../../adapters/visit/updateVisit';
// COMPONENTS
import Select from 'react-select';
import {Button, Card, Form} from 'react-bootstrap';
import Treatment from '../../pages/Treatment/Treatment';
// TYPES
import {INewTreatment} from '../../types/newTreatmentInterface';
import {ITreatment} from '../../types/treatmentInterface';

interface IAddTreatmentProps {
	patientId: number;
	addNotification: any;
	close: any;
	visitId?: number;
}

const AddTreatment = (props: IAddTreatmentProps) => {
	const [isVisitsLoading, setIsVisitsLoading] = useState(true);
	const [treatment, setTreatment] = useState<INewTreatment>({
		name: '',
		icd: '',
		icf: '',
		patient_id: props.patientId,
	});
	const [treatmentsList, setTreatmentsList] = useState<ITreatment[]>(new Array<ITreatment>());
	const [selectedTreatment, setSelectedTreatment] = useState<ITreatment | undefined>(undefined);
	let history = useHistory();

	useEffect(() => {
		patientsTreatments(props.patientId)
			.then((result) => {
				setIsVisitsLoading(false);
				setTreatmentsList(result as ITreatment[]);
				selectEntryTreatment(result as ITreatment[]);
			})
			.catch((e) => {
				//console.log(e);
				setIsVisitsLoading(false);
			});
		return () => {};
	}, []);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTreatment({
			...treatment,
			[event.currentTarget.name]: event.currentTarget.value,
		});
	};

	const selectEntryTreatment = (treatments: ITreatment[]) => {
		for (let i = 0; i < treatments.length; i++) {
			if (!treatments[i].status) {
				setSelectedTreatment(treatments[i]);
				break;
			}
		}
	};

	const handleAddFormSubmit = (e: React.FormEvent) => {
		e.preventDefault();

		if (treatment.name && treatment.patient_id) {
			addTreatment(treatment)
				.then((response) => {
					if (response.treatment) {
						setTreatmentsList([...treatmentsList, response.treatment]);
						setSelectedTreatment(response.treatment);
					}

					return response.message;
				})
				.then((message) => props.addNotification(message))
				.catch((e) => props.addNotification(e.message));
		}
	};

	const handleTreatmentSelect = (treatmentId: number) => {
		treatmentsList.forEach((treatment) => {
			if (treatment.id === treatmentId) {
				setSelectedTreatment({...treatment});
			}
		});
	};

	const handleVisitStarted = () => {
		if (props.visitId && selectedTreatment)
			updateVisit({
				id: props.visitId,
				treatment_id: selectedTreatment?.id,
			})
				.then((response) => {
					history.push({
						pathname: '/treatment',
						state: {treatment: {...selectedTreatment}, patientId: props.patientId},
					});
				})
				.catch((e) => console.log(e));
	};

	return (
		<Card
			className={`add-visit position-absolute w-100 h-100 popup ${isVisitsLoading ? 'loading' : ''}`}
			style={{zIndex: 20, top: 0}}
		>
			<Card.Header>Rozpocznij wizytę</Card.Header>
			<Card.Body>
				<Form.Group className='mb-5'>
					<Form.Label>Wybierz leczenie</Form.Label>
					<div className='d-flex align-items-center justify-content-between'>
						<Select
							className='over__clendar w-75'
							options={treatmentsList
								.filter((treatment) => treatment.status == false)
								.map((treatment) => {
									return {value: treatment.id, label: `${treatment.name}`};
								})}
							value={selectedTreatment && {value: selectedTreatment.id, label: selectedTreatment.name}}
							placeholder='Wybierz leczenie'
							onChange={(value) => {
								if (value?.value) handleTreatmentSelect(Number(value.value));
							}}
							isSearchable
						/>
						<Button
							variant='success'
							className='ml-4 h-100'
							onClick={() => {
								handleVisitStarted();
							}}
						>
							Rozpocznij
						</Button>
					</div>
				</Form.Group>
				<Form onSubmit={handleAddFormSubmit}>
					<Form.Label>Dodaj nowe leczenie</Form.Label>
					<Form.Group controlId='exampleForm.ControlTextarea1'>
						<Form.Label className='required'>Nazwa</Form.Label>
						<Form.Control type='text' name='name' value={treatment.name} onChange={handleChange} required />
					</Form.Group>
					<div className='d-flex w-100 justify-content-between'>
						<Button type='submit' variant='success' className='mt-5'>
							Dodaj
						</Button>
						<Button onClick={props.close} variant='danger' className='mt-5'>
							Zamknij
						</Button>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addNotification: (payload: string) => dispatch(notificationActions.add(payload)),
	};
};

export default connect(null, mapDispatchToProps)(AddTreatment);
