import {deleteToken} from '../../helpers/sessionHelper';
import axios from '../xhr';
import store from '../../redux/store';
import {tokenActions} from '../../redux/token/index';
import {userActions} from '../../redux/user/index';

const logout = () => {
  axios
    .post('/logout', {})
    .then((response) => {
      store.dispatch(tokenActions.reset());
      store.dispatch(userActions.reset());
      deleteToken();
      window.location.reload();
    })
    .catch((e) => {
      window.location.reload();
    });
};

export default logout;
