import React, {useEffect, useState} from 'react';
import {push} from 'connected-react-router';
import {connect} from 'react-redux';
import {Accordion, Button, Card, Col, Row} from 'react-bootstrap';
import patients from '../../adapters/patient/getPatientsParts';
import patientsSearch from '../../adapters/patient/getPatientsSearch';
import IPatient from '../../types/patientInterface';
import deletePatient from '../../adapters/patient/deletePatient';
// COMPONENTS
import Treatment from '../../components/Treatment/Treatment';
import AddPatientForm from '../../components/Forms/AddPatientForm';
import Confirm from '../../components/Confirm/Confirm';

const Patients = (props: any) => {
	const [patientsData, setPatientsData] = useState<IPatient[]>(new Array<IPatient>());
	const [filter, setFilter] = useState<string>('');
	const [addPatient, setAddPatient] = useState<null | JSX.Element>(null);
	const [confirm, setConfirm] = useState<undefined | JSX.Element>(undefined);
	const [opendedPatients, setOpenedPatients] = useState<number[]>([]);
	const [patientsCount, setPatientsCount] = useState<number>(-1);
	const [searchList, setSearchList] = useState<IPatient[]>([]);
	const [searchTimeout, setSearchTimeout] = useState<null | ReturnType<typeof setTimeout>>(null);

	useEffect(() => {
		if (patientsData.length === 0) {
			fetchPatients();
		}

		return () => {};
	}, []);

	const fetchPatients = () => {
		patients(patientsData.length)
			.then((response) => {
				// console.log(response);
				const res: {count: number; list: IPatient[]} = response as {count: number; list: IPatient[]};
				setPatientsData([...patientsData, ...res.list]);
				setPatientsCount(res.count);
			})
			.catch((e) => {
				props.push('/');
			});
	};

	useEffect(() => {
		if (filter.length >= 3) {
			if (searchTimeout) {
				clearTimeout(searchTimeout);
			}

			setSearchTimeout(
				setTimeout(() => {
					fetchSearch();
				}, 600)
			);
		} else {
			if (searchTimeout) {
				clearTimeout(searchTimeout);
			}
		}
	}, [filter]);

	const fetchSearch = (): void => {
		patientsSearch(filter)
			.then((response) => {
				// console.log(response);
				setSearchList(response as IPatient[]);
			})
			.catch((e) => {
				props.push('/');
			});
	};

	const handleClick = (close?: boolean) => {
		if (close) {
			setAddPatient(null);
		} else {
			setAddPatient(<AddPatientForm addedPatient={handleNewPatient} isPopUp={true} closeHandler={handleClick} />);
		}
	};

	const handleNewPatient = (patient: IPatient) => {
		if (patient) {
			setPatientsData([...patientsData, patient]);
		}
	};

	const handleConfirmedDelete = (id: number) => {
		deletePatient(id).then((res) => {
			setPatientsData(patientsData.filter((patient) => patient.id !== id));
		});
		setConfirm(undefined);
	};

	const handleDelete = (id: number | undefined) => {
		if (id) {
			setConfirm(
				<Confirm
					message='Czy napewno chcesz usunąć pacjenta?'
					onAccept={handleConfirmedDelete}
					onReject={(p: any) => {
						setConfirm(undefined);
					}}
					params={id}
				/>
			);
		}
	};

	return (
		<Row>
			<Col>
				<div className='d-flex justify-content-center align-items-end px-5'>
					<div className='d-flex justify-content-center flex-column align-items-cester w-50 mx-auto mb-5'>
						<label className='mb-3'>Szukaj pacjenta</label>
						<input type='text' value={filter} onChange={(e) => setFilter(e.target.value)} />
					</div>
					<button
						type='button'
						className='btn btn-outline-success w-auto mx-auto mb-5'
						onClick={() => handleClick()}
					>
						Dodaj pacjenta
					</button>
				</div>
				<div className='position-relative w-100 mx-auto' style={{zIndex: 280}}>
					{addPatient && addPatient}
				</div>

				<Accordion defaultActiveKey='0'>
					{filter.length >= 3
						? searchList.map((patient) => (
								<Card key={patient.id} className='my-2'>
									<div className='w-100 d-flex justify-content-between align-items-center px-3 py-2'>
										<span>
											{patient.lastname} {patient.name} {patient.email}{' '}
											{patient.nextVisit && (
												<span>
													<b>Najbliższa wizyta:</b> {patient.nextVisit}
												</span>
											)}
										</span>
										<Accordion.Toggle
											as={Button}
											type='button'
											eventKey={patient.id ? patient.id.toString() : ''}
											onClick={() => {
												if (patient.id) {
													setOpenedPatients([...opendedPatients, patient.id]);
												}
											}}
										>
											Więcej
										</Accordion.Toggle>
									</div>
									<Accordion.Collapse eventKey={patient.id ? patient.id.toString() : ''}>
										<Card.Body className='px-5 d-flex flex-column'>
											<button
												className='btn btn-outline-danger w-auto mx-auto mb-1 mx-auto'
												onClick={() => handleDelete(patient.id)}
											>
												Usuń pacjenta
											</button>
											{patient.id && props.isFizjo && opendedPatients.includes(patient.id) && (
												<Treatment patientId={patient.id} />
											)}
											{patient.id && !props.isFizjo && (
												<AddPatientForm isPopUp={false} patient={patient} />
											)}
										</Card.Body>
									</Accordion.Collapse>
								</Card>
						  ))
						: patientsData.map((patient) => (
								<Card key={patient.id} className='my-2'>
									<div className='w-100 d-flex justify-content-between align-items-center px-3 py-2'>
										<span>
											{patient.lastname} {patient.name} {patient.email}{' '}
											{patient.nextVisit && (
												<span>
													<b>Najbliższa wizyta:</b> {patient.nextVisit}
												</span>
											)}
										</span>
										<Accordion.Toggle
											as={Button}
											type='button'
											eventKey={patient.id ? patient.id.toString() : ''}
											onClick={() => {
												if (patient.id) {
													setOpenedPatients([...opendedPatients, patient.id]);
												}
											}}
										>
											Więcej
										</Accordion.Toggle>
									</div>
									<Accordion.Collapse eventKey={patient.id ? patient.id.toString() : ''}>
										<Card.Body className='px-5 d-flex flex-column'>
											<button
												className='btn btn-outline-danger w-auto mx-auto mb-1 mx-auto'
												onClick={() => handleDelete(patient.id)}
											>
												Usuń pacjenta
											</button>
											{patient.id && props.isFizjo && opendedPatients.includes(patient.id) && (
												<Treatment patientId={patient.id} />
											)}
											{patient.id && !props.isFizjo && (
												<AddPatientForm isPopUp={false} patient={patient} />
											)}
										</Card.Body>
									</Accordion.Collapse>
								</Card>
						  ))}
				</Accordion>
				{filter.length < 3 && patientsData.length < patientsCount && (
					<button
						onClick={() => {
							fetchPatients();
						}}
						type='button'
						className='btn btn-outline-success w-auto mx-auto mt-5'
					>
						Załaduj więcej {`(${patientsData.length}/${patientsCount})`}
					</button>
				)}
			</Col>
			{confirm && confirm}
		</Row>
	);
};

export default connect(null, {push})(Patients);
