import axios from '../xhr';
import INewUser from '../../types/NewUserInterface';

export interface IRegisterResponse {
  status: number;
  message: string;
  errors?: string[];
}

const addUser = (userData: INewUser): Promise<IRegisterResponse> => {
  const response = axios
    .post('/register', userData)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve({
          status: 200,
          message: 'Użytkownik został pomyślnie dodany',
        });
      }
      if (response.status === 409) {
        return Promise.resolve({
          status: 409,
          message: response.data.message,
        });
      }
      if (response.status === 422) {
        return Promise.resolve({
          status: 422,
          message: 'Nie poprawne pola',
          errors: response.data.errors,
        });
      }

      return Promise.reject({
        status: 499,
        message: 'Nie znany błąd',
      });
    })
    .catch((e) => {
      return Promise.reject({
        status: 500,
        message: 'Wewnętrzny błąd serwera',
      });
    });

  return response;
};

export default addUser;
