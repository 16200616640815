import axios from '../xhr';
// TYPES
import IVisit from '../../types/visitInterface';

export interface IRegisterResponse {
  status: number;
  message: string;
  errors?: string[];
  visit?: IVisit;
}

export interface IVisitToAdd extends Omit<IVisit, 'visit_duration'> {
  visit_duration: number;
}

const addVisit = (visitData: IVisitToAdd): Promise<IRegisterResponse> => {
  const response = axios
    .post('visit', visitData)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve({
          status: 200,
          message: 'Wizyta została pomyślnie dodana',
          visit: response.data.visit,
        });
      }
      if (response.status === 409) {
        return Promise.resolve({
          status: 409,
          message: response.data.message,
        });
      }
      if (response.status === 422) {
        return Promise.resolve({
          status: 422,
          message: 'Nie poprawne pola',
          errors: response.data.errors,
        });
      }

      return Promise.reject({
        status: 499,
        message: 'Nie znany błąd',
      });
    })
    .catch((e) => {
      return Promise.reject({
        status: 500,
        message: 'Wewnętrzny błąd serwera',
      });
    });

  return response;
};

export default addVisit;
