import axios from '../xhr';
import {IUser} from '../../redux/user/types';

export interface IResponse {
	status: number;
	message: string;
}

const users = (): Promise<IResponse | IUser[]> => {
	const response = axios
		.get('/users')
		.then((response) => {
			if (response.status === 200) {
				return Promise.resolve(response.data.filter((user: IUser) => user.id !== 1 && user.id !== 2));
			} else if (response.status === 401) {
				return Promise.reject({
					status: 401,
					message: response.data.message,
				});
			} else {
				throw new Error('Troubles');
			}
		})
		.catch((e) => {
			return Promise.reject({
				status: e.status,
				message: e.message,
			});
		});

	return response;
};

export default users;
