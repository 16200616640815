import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Redirect, Route} from 'react-router-dom';
import {ConnectedRouter as Router} from 'connected-react-router';
import {connect} from 'react-redux';
// STYLES
import './App.scss';
// COMPONENTS
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import Navbar from './components/Navbar/Navbar';
import AddUserForm from './components/Forms/AddUserForm';
import Statusbar from './components/Statusbar/Statusbar';
// Modules
import defaultUser from './helpers/defaultUser';
import {history} from './redux/store';
// PAGES
import MySchedules from './pages/MySchedules/MySchedules';
import UsersSchedules from './pages/UsersSchedules/UsersSchedules';
import MyCalendar from './pages/MyCalendar/MyCalendar';
import Login from './pages/Login/Login';
import Profile from './pages/Profile/Profile';
import Users from './pages/Users/Users';
import Patients from './pages/Patients/Patients';
import Calendars from './pages/Calendars/Calendars';
import Treatment from './pages/Treatment/Treatment';
import Codes from './pages/Codes/Codes';
import VisitsRaport from './pages/VisitsRaport/VisitsRaport';

function App(props: any) {
	return (
		<Router history={history}>
			<Container fluid>
				{props.user.id >= 0 && <Statusbar />}
				<Row className='pt-5'>
					<Col sm={1} className='position-relative'>
						{props.user.id >= 0 && <Navbar />}
					</Col>
					<Col sm={12} style={{paddingLeft: '80px'}}>
						{props.user.permission === 'recepcja' ? (
							<ProtectedRoute
								isPermitted={props.user.id >= 0}
								path='/'
								exact
								component={() => <Redirect to={{pathname: '/calendars'}} />}
							/>
						) : (
							<ProtectedRoute
								isPermitted={props.user.id >= 0}
								path='/'
								exact
								component={() => <Redirect to={{pathname: '/mycalendar'}} />}
							/>
						)}

						<Route path='/login' exact render={({history}) => <Login />} />
						<ProtectedRoute
							isPermitted={props.user.permission === 'admin'}
							path='/users'
							exact
							component={() => <Users />}
						/>
						<ProtectedRoute
							isPermitted={props.user.permission === 'admin'}
							path='/codes'
							exact
							component={() => <Codes />}
						/>
						<ProtectedRoute
							isPermitted={true}
							path='/patients'
							exact
							component={() => <Patients isFizjo={true} />} //props.user.permission !== 'recepcja'
						/>
						<ProtectedRoute
							isPermitted={props.user.permission === 'admin' || props.user.permission === 'recepcja'}
							path='/usersSchedules'
							exact
							component={() => <UsersSchedules />}
						/>
						<ProtectedRoute
							isPermitted={props.user.permission === 'admin' || props.user.permission === 'fizjo'}
							path='/treatment'
							exact
							component={() => <Treatment />}
						/>
						<ProtectedRoute
							isPermitted={props.user.permission === 'admin' || props.user.permission === 'recepcja'}
							path='/calendars'
							exact
							component={() => <Calendars user={props.user} />}
						/>
						<ProtectedRoute
							isPermitted={true}
							path='/profile'
							exact
							component={() => <Profile user={props.user} />}
						/>
						<ProtectedRoute
							isPermitted={props.user.permission === 'admin' || props.user.permission === 'fizjo'}
							path='/mycalendar'
							exact
							component={() => <MyCalendar user={props.user} />}
						/>
						<ProtectedRoute
							isPermitted={true}
							path='/schedules'
							exact
							component={() => <MySchedules user={props.user} />}
						/>
						<ProtectedRoute isPermitted={true} path='/raports' exact component={() => <VisitsRaport />} />
						<ProtectedRoute
							isPermitted={props.user.permission === 'admin'}
							path='/adduser'
							exact
							component={() => (
								<AddUserForm
									user={defaultUser}
									title='Dodaj nowego użytkownika'
									buttonText='Dodaj użytkownika'
								/>
							)}
						/>
					</Col>
				</Row>
			</Container>
		</Router>
	);
}

const mapStateToProps = (state: any) => ({
	user: state.user,
});

export default connect(mapStateToProps)(App);
