import axios from '../xhr';
import IPatient from '../../types/patientInterface';

export interface IResponse {
	status: number;
	message: string;
}

const patients = (offset: number): Promise<IResponse | {count: number; list: IPatient[]}> => {
	const response = axios
		.get(`/patients/parts?limit=60&offset=${offset}`)
		.then((response) => {
			if (response.status === 200) {
				return Promise.resolve(response.data.data);
			} else if (response.status === 401) {
				return Promise.reject({
					status: 401,
					message: response.data.message,
				});
			} else {
				throw new Error('Troubles');
			}
		})
		.catch((e) => {
			//console.log(e);
			return Promise.reject({
				status: e.status,
				message: e.message,
			});
		});

	return response;
};

export default patients;
