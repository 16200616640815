import axios from '../xhr';
import { ISchedule } from '../../types/SheduleInterface';

export interface IRegisterResponse {
  status: number;
  message: string;
  errors?: string[];
}

const addOrUpdateSchedules = (
  schedulesData: ISchedule[]
): Promise<IRegisterResponse> => {
  const response = axios
    .post('/addSchedule', schedulesData)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve({
          status: 200,
          message: 'Harmonogram użytkownika zostały zaktualizowany',
        });
      }
      if (response.status === 405) {
        return Promise.resolve({
          status: 405,
          message: response.data.message,
        });
      }

      return Promise.reject({
        status: 499,
        message: 'Nie znany błąd',
      });
    })
    .catch((e) => {
      return Promise.reject({
        status: 500,
        message: 'Wewnętrzny błąd serwera',
      });
    });

  return response;
};

export default addOrUpdateSchedules;
