import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form} from 'react-bootstrap';
import FormMessage from './FormMessage';
import SchedulesDay from './SchedulesDay/SchedulesDay';
import {ISchedule} from '../../types/SheduleInterface';
import defaultSchedules from '../../types/defaults/schedulesArray';
import {IUser} from '../../redux/user/types';
import addOrUpdateSchedules, {IRegisterResponse} from '../../adapters/schedules/addOrUpdateSchedules';
import schedules from '../../adapters/schedules/schedules';
// MODULES
import {notificationActions} from '../../redux/notifications/index';
import {connect} from 'react-redux';

export interface ISchedulesFormProps {
	user: IUser;
	addNotification: any;
}

const SchedulesForm = (props: ISchedulesFormProps) => {
	const [state, setState] = useState<ISchedule[]>(defaultSchedules(props.user.id));

	useEffect(() => {
		schedules(props.user.id)
			.then((response) => {
				//console.log(response);
				if ((response as ISchedule[]).length >= 0 && (response as ISchedule[]).length < 6) {
					let schedules = [...(response as ISchedule[])];
					//console.log((response as ISchedule[]).length);
					for (let i = 0; i < 6; i++) {
						let contain = false;

						schedules.forEach((schedule) => {
							if (schedule.day === i) contain = true;
						});

						if (!contain) schedules.push(defaultSchedules(props.user.id)[i]);
					}

					schedules.sort((a, b) => (a.day > b.day ? 1 : b.day > a.day ? -1 : 0));
					setState(schedules);
				} else {
					setState(response as ISchedule[]);
				}
			})
			.catch((e) => {
				setState(defaultSchedules(props.user.id));
				//console.log(e.message);
			});

		return () => {};
	}, [props.user]);

	const handleChange = (
		event:
			| React.ChangeEvent<HTMLInputElement>
			| {
					currentTarget: {
						name: string;
						dataset: {day: string};
						type: string;
						value: any;
						checked: boolean;
					};
			  }
	) => {
		let schedules = [...state];

		const newSchedules = schedules.map((schedule) => {
			if (schedule.day === Number(event.currentTarget.dataset.day)) {
				if (event.currentTarget.type === 'checkbox') {
					return {
						...schedule,
						[event.currentTarget.name]: event.currentTarget.checked,
					};
				}
				return {
					...schedule,
					[event.currentTarget.name]: event.currentTarget.value,
				};
			} else if (Number(event.currentTarget.dataset.day) > 5 && event.currentTarget.type === 'date') {
				return {
					...schedule,
					[event.currentTarget.name]: event.currentTarget.value,
				};
			} else return schedule;
		});

		if (newSchedules !== undefined) setState([...(newSchedules as ISchedule[])]);
	};

	const handleSubmit = (e: React.FormEvent) => {
		if (state[0].user_id >= 0) {
			addOrUpdateSchedules(state)
				.then((resp: IRegisterResponse) => {
					props.addNotification(resp.message);
				})
				.catch((e) => props.addNotification(e.message));
		}
		e.preventDefault();
	};

	return (
		<Card>
			<Card.Header as='h5'>
				Harmonogram - {props.user.lastname} {props.user.name}
			</Card.Header>
			<Card.Body>
				<Form onSubmit={handleSubmit}>
					<div className='row d-flex justify-content-center'>
						<Col sm={3}>
							<Form.Group className='mb-3' controlId='formBasicPassword'>
								<Form.Label className='required'>Od</Form.Label>
								<Form.Control
									type='date'
									placeholder='rrrr-mm-dd'
									name='start_date'
									value={state[0] && state[0].start_date}
									onChange={(event) => handleChange(event as any)}
									data-day={8}
									max={state[0] && state[0].end_date}
									required
								/>
							</Form.Group>
						</Col>
						<Col sm={3}>
							<Form.Group className='mb-3' controlId='formBasicPassword'>
								<Form.Label className='required'>Do</Form.Label>
								<Form.Control
									type='date'
									placeholder='rrrr-mm-dd'
									name='end_date'
									value={state[0] && state[0].end_date}
									onChange={(event) => handleChange(event as any)}
									data-day={8}
									min={state[0] && state[0].start_date}
									required
								/>
							</Form.Group>
						</Col>
					</div>
					{state.map((schedule) => (
						<SchedulesDay onChange={handleChange} schedule={schedule} key={schedule.day} />
					))}

					<div className='w-100 d-flex justify-content-end'>
						<Button variant='success' type='submit' className='mt-4'>
							Zapisz harmonogram
						</Button>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addNotification: (payload: string) => dispatch(notificationActions.add(payload)),
	};
};

export default connect(null, mapDispatchToProps)(SchedulesForm);
