import React from 'react';
import {offsetToTime} from '../../helpers/time';
import {CONSTS, TaskObj} from './prepareVisits';
// STYLES
import './Task.scss';

export enum Types {
	Schedule,
	Visit,
	AdHock,
	Done,
}

const classMap = new Map([
	[Types.Visit, 'button__visit'],
	[Types.Schedule, 'button__free'],
	[Types.AdHock, 'button__adhock'],
	[Types.Done, 'button__done'],
]);

const textMap = new Map([
	[Types.Visit, 'Wizyta'],
	[Types.Schedule, 'Wolne'],
	[Types.AdHock, ''],
	[Types.Done, 'Odbyta'],
]);

export interface ITaskProps {
	//   height: number;
	//   topOffset: number;
	//   type: Types;
	onClick: any;
	task: TaskObj;
	date: Date;
}

const Task = (props: ITaskProps) => {
	const index = props.task.type === Types.Visit ? 10 : 1;

	const getText = () => {
		if (props.task.props) {
			if (props.task.props.done) {
				return textMap.get(Types.Done);
			}
		}

		return textMap.get(props.task.type);
	};

	return (
		<button
			onClick={(e) =>
				props.onClick(
					false,
					{
						date: props.date,
						duration: props.task.duration,
						start: offsetToTime(props.task.start),
					},
					props.task.props ? (props.task.props.done ? true : false) : false,
					props.task.props ? props.task.props.id : undefined,
					props.task.props
				)
			}
			className={`list__item free d-flex flex-column justify-content-center align-items-center 
				${classMap.get(props.task.type)} ${props.task.props ? (props.task.props.confirmed ? 'confirmed' : '') : ''} ${
				props.task.props ? (props.task.props.done ? 'button__done' : '') : ''
			}`}
			style={{
				top: `${((props.task.start - CONSTS.BEGIN_DAY_STEP) / CONSTS.TIME_INTERVAL) * CONSTS.BASE_HEIGHT}px`,
				height: `${(props.task.duration / CONSTS.TIME_INTERVAL) * CONSTS.BASE_HEIGHT}px`,
				zIndex: index,
			}}
		>
			<div>{getText()}</div>
			{props.task.props && (
				<div>
					{props.task.props.lastname} {props.task.props.name}
				</div>
			)}
			{props.task.type === Types.Visit && (
				<div>
					{offsetToTime(props.task.start)} - {offsetToTime(props.task.end)}
				</div>
			)}
			{props.task.props ? props.task.props.consultations ? <i className='bi bi-lightbulb-fill'></i> : '' : ''}
		</button>
	);
};

export default Task;
