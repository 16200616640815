import types, { IUser } from './types';

const set = (user: IUser) => ({
  type: types.SET,
  user,
});

const reset = () => ({
  type: types.RESET,
});

const actions = {
  reset,
  set,
};

export default actions;
