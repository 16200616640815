const weekDays = (dateInWeek: Date): Array<Date> => {
	let days = new Array<Date>();

	const currentDate = new Date(dateInWeek);
	const first = currentDate.getDate() - currentDate.getDay() + 1;
	console.log(currentDate.getDate() - currentDate.getDay());

	if (first >= 0) {
		currentDate.setDate(first);
	} else if (first < 0) {
		currentDate.setDate(0);
		currentDate.setDate(currentDate.getDate() - Math.abs(first));
	}

	for (let i = 0; i < 6; i++) {
		const date = new Date(currentDate);
		date.setDate(currentDate.getDate() + i);
		date.setHours(0, 0, 0, 0);
		days.push(new Date(date));
	}

	return days;
};

export default weekDays;
