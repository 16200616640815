import React, {useEffect, useState} from 'react';
import './DayColumn.scss';
// MODULES
import dateHelper from './helpers/date';
import schedules from '../../adapters/schedules/schedules';
import {TaskObj} from './prepareVisits';
// TYPES
import {IUser} from '../../redux/user/types';
import {ISchedule} from '../../types/SheduleInterface';
import IVisitInterface from '../../types/visitInterface';
// COMPONENTS
import Task, {Types} from './Task';

export interface IDayColumnProps {
	// task: ITasksList;
	date: string;
	handleClick: any;
	userId: number;
	tasks: TaskObj[];
}

const days = ['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'];

const daysEn = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const DayColumn = (props: IDayColumnProps) => {
	//console.log(props.tasks);

	return (
		<div className='day'>
			<div className='day__date'>
				{days[new Date(props.date).getDay() - 1]} <br />
				{props.date}
			</div>
			<div className='day__list'>
				{props.tasks.map((task) => (
					<Task onClick={props.handleClick} task={task} date={new Date(props.date)} />
				))}
				{/* {prepareTaskList(props.task, visitList(visits), props.task.task.interval).map((task) => (
          <Task
            onClick={props.handleClick}
            height={task.height}
            topOffset={task.offsetTop}
            type={task.task.type}
            key={task.offsetTop}
            task={task.task}
            date={props.date}
          />
        ))} */}
			</div>
		</div>
	);
};

export default DayColumn;
