import React, {useState, useEffect} from 'react';
import {push} from 'connected-react-router';
import {connect} from 'react-redux';
// MODULES
import login from '../../adapters/auth/login';
import user from '../../adapters/user/user';
// STYLES
import './Login.scss';
import {Card} from 'react-bootstrap';

const Login = (props: any) => {
  const [state, setState] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    if (props.user.id >= 0) props.push('/');
  }, [props.user.id]);

  const [message, setMessage] = useState<string>();

  const handleSubmit = (e: React.FormEvent) => {
    setMessage('');

    if (state.email && state.password)
      login(state)
        .then(() => {
          user()
            .then(() => props.push('/'))
            .catch((e) => setMessage(e.messages));
        })
        .catch((e) => setMessage(e.message));

    e.preventDefault();
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  return (
    <div className='container login'>
      <form className='login__form' onSubmit={(e) => handleSubmit(e)}>
        <Card>
          <Card.Header as='h5' className='text-center'>
            Zaloguj się
          </Card.Header>
          <Card.Body>
            <div className='m-3'>
              <label className='form-label text-center w-100'>Adres e-mail</label>
              <input
                type='email'
                className='form-control text-center'
                id='exampleInputEmail1'
                name='email'
                value={state.email}
                onChange={handleChange}
                required
              />
              <div id='emailHelp' className='form-text text-danger text-center w-100'>
                {message}
              </div>
            </div>
            <div className='m-3'>
              <label className='form-label text-center w-100'>Hasło</label>
              <input
                type='password'
                className='form-control text-center'
                id='exampleInputPassword1'
                name='password'
                value={state.password}
                onChange={handleChange}
                required
              />
            </div>
            <div className='w-100 d-flex justify-content-center mt-4'>
              <button type='submit' className='btn btn-success w-100 m-3'>
                Zaloguj
              </button>
            </div>
          </Card.Body>
        </Card>
      </form>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user,
});

export default connect(mapStateToProps, {push})(Login);
