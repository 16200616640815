import {ITreatment} from '../../types/treatmentInterface';
import axios from '../xhr';

export interface IResponse {
	status: number;
	message: string;
}

const patientsTreatments = (id: number): Promise<IResponse | ITreatment> => {
	const response = axios
		.get(`/treatment/${id}`)
		.then((response) => {
			if (response.status === 200) {
				return Promise.resolve(response.data);
			} else if (response.status === 401) {
				return Promise.reject({
					status: 401,
					message: response.data.message,
				});
			} else {
				//console.log(response);
				throw new Error('Troubles');
			}
		})
		.catch((e) => {
			return Promise.reject({
				status: e.status,
				message: e.message,
			});
		});

	return response;
};

export default patientsTreatments;
