import axios, {AxiosInstance, AxiosResponse} from 'axios';

export const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8000/api/' : '/api/';

class xhr {
	private init(): AxiosInstance {
		let headers: Object = {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			'Access-Control-Allow-Origin': '*',
		};

		if (sessionStorage.getItem('token') !== null) {
			headers = {
				...headers,
				Authorization: `Bearer ${sessionStorage.getItem('token')}`,
			};
		}

		const axiosInstance = axios.create({
			baseURL: BASE_URL,
			headers: headers,
			timeout: 30000,
			validateStatus: function (status) {
				return status >= 200 && status < 450;
			},
		});

		return axiosInstance;
	}

	get(url: string): Promise<AxiosResponse> {
		return this.init().get(url);
	}

	post(url: string, requestData: any): Promise<AxiosResponse> {
		return this.init().post(url, requestData);
	}

	patch(url: string, requestData: any): Promise<AxiosResponse> {
		return this.init().patch(url, requestData);
	}

	delete(url: string): Promise<AxiosResponse> {
		return this.init().delete(url);
	}
}

export default new xhr();
