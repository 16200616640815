import React from 'react';
import patients from '../../adapters/patient/getPatients';
import {useLocation} from 'react-router-dom';
// COMPONENTS
import TreatmentComponent from '../../components/Treatment/Treatment';
// INTERFACES
import {ITreatment} from '../../types/treatmentInterface';

export interface ITreatmentProps {
	treatment?: ITreatment;
	patientId?: number;
}

export interface ILocationState {
	treatment: ITreatment;
	patientId: number;
}

const Treatment = (props: ITreatmentProps) => {
	const location = useLocation<ILocationState>();

	return (
		(location.state && (
			<TreatmentComponent patientId={location.state.patientId} treatment={location.state.treatment} />
		)) || <div></div>
	);
};

export default Treatment;
