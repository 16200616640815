import React, {useState} from 'react';
// STYLES
import './Tabs.scss';

export interface ITabsProps {
  children?: React.ReactNode;
  tabs: string[];
}

const Tabs = (props: ITabsProps) => {
  const handleClick = (e: React.MouseEvent) => {
    document.querySelectorAll('[data-tab]').forEach((tab) => {
      if (tab.getAttribute('data-tab') === e.currentTarget.getAttribute('data-tab-name')) {
        tab.classList.add('active');
      } else {
        tab.classList.remove('active');
      }
    });

    document.querySelectorAll('[data-tab-name]').forEach((tab) => {
      if (tab.getAttribute('data-tab-name') === e.currentTarget.getAttribute('data-tab-name')) {
        tab.classList.add('btn-primary');
        tab.classList.remove('btn-outline-primary');
      } else {
        tab.classList.remove('btn-primary');
        tab.classList.add('btn-outline-primary');
      }
    });
  };

  return (
    <div className='tabs'>
      <div className='tabs__nav'>
        {props.tabs.map((tab) => (
          <button key={tab} onClick={handleClick} data-tab-name={tab} className='btn btn-primary nav__item'>
            {tab}
          </button>
        ))}
      </div>
      <div className='tabs__container'>{props.children}</div>
    </div>
  );
};

export default Tabs;
