class PeselDecoder {
  private isValid = false;
  private pesel = '';

  constructor(pesel: string) {
    this.pesel = pesel;
  }

  public getDate = (): string => {
    let year = parseInt(this.pesel.substring(0, 2), 10);
    let month = parseInt(this.pesel.substring(2, 4), 10);
    let day = parseInt(this.pesel.substring(4, 6), 10);

    if (month >= 80) {
      year += 1800;
      month = month - 80;
    } else if (month >= 60) {
      year += 2200;
      month = month - 60;
    } else if (month >= 40) {
      year += 2100;
      month = month - 40;
    } else if (month >= 20) {
      year += 2000;
      month = month - 20;
    } else {
      year += 1900;
    }

    return `${year}-${month.toString().padStart(2, '0')}-${day
      .toString()
      .padStart(2, '0')}`;
  };

  public getGender = (): string => {
    return parseInt(this.pesel.substring(9, 10), 10) % 2 === 1
      ? 'male'
      : 'female';
  };

  public validate = (): boolean => {
    for (let i = 0; i < 11; i++) {
      if (isNaN(parseInt(this.pesel.substring(i, i + 1)))) {
        return false;
      }
    }

    let weights = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];
    let sum = 0;

    for (var i = 0; i < weights.length; i++) {
      sum += parseInt(this.pesel.substring(i, i + 1), 10) * weights[i];
    }

    sum = sum % 10;

    return sum === parseInt(this.pesel.substring(10, 11), 10);
  };
}

export default PeselDecoder;
