import types, {INotification} from './types';
import {AnyAction} from 'redux';

const initialState: INotification[] = new Array<INotification>();

const change = (state: INotification[], notification: INotification): INotification[] => {
	const index = state.findIndex((element) => element.time === notification.time);

	state[index] = notification;
	//console.log(state.length);

	return state;
};

const tokenReducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case types.ADD:
			return [...state, {message: action.message, isNew: true, time: new Date().getTime()}];
		case types.RESET:
			return [];
		case types.CHANGE:
			return [...change([...state], action.notification)];
		default:
			return state;
	}
};

export default tokenReducer;
