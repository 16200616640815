export const timeToOffset = (timeSting: string): number => {
    const hours = Number(timeSting.split(':')[0]);
    const minutes = Number(timeSting.split(':')[1]);
    
    return hours * 60 - minutes;
}

export const offsetToTime = (offset: number): string => {
    const hours = Math.floor(offset / 60);
    const minutes = offset % 60
    
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}