import React from 'react';
// MODULES
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import { connect } from 'react-redux';
// TYPES
import calendarDateTypes from '../../redux/calendarDate/types';
// STYLES
import './DatePicker.scss';

const daysShorts = ['N', 'P', 'W', 'Ś', 'C', 'Pt', 'S'];

const DatePicker = (props: { setCalendarDate: any }) => {
	const handleDateClick = (info: any) => {
		props.setCalendarDate(new Date(info.dateStr));
	};

	return (
		<FullCalendar
			plugins={[dayGridPlugin, interactionPlugin]}
			dateClick={handleDateClick}
			locale='pl'
			headerToolbar={{ left: 'title', right: 'prev,next' }}
			firstDay={1}
			dayHeaderContent={arg => daysShorts[arg.date.getDay()]}
			dayHeaders={false}
		/>
	);
};

const mapStateToProps = (state: any) => ({
	calendarDate: state.calendarDate,
});

const mapDispatchToProps = (dispatch: any) => {
	return {
		setCalendarDate: (payload: Date) =>
			dispatch({ type: calendarDateTypes.SET, date: payload }),
	};
};

export default connect(null, mapDispatchToProps)(DatePicker);
