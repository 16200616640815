import types from './types';

const set = (token: string) => ({
  type: types.SET,
  token,
});

const reset = () => ({
  type: types.RESET,
});

const actions = {
  reset,
  set,
};

export default actions;
