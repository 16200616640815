import axios from '../xhr';
import { deleteToken } from '../../helpers/sessionHelper';
import store from '../../redux/store';
import { tokenActions } from '../../redux/token/index';
import { userActions, userTypes } from '../../redux/user/index';
import { IUser } from '../../redux/user/types';

export interface IResponse {
  status: number;
  message: string;
}

const user = (): Promise<IResponse> => {
  const response = axios
    .get('/user')
    .then((response) => {
      if (response.status === 200) {
        const userData: IUser = {
          id: response.data.id,
          name: response.data.name,
          email: response.data.email,
          permission: response.data.permission,
          lastname: response.data.lastname,
          pesel: response.data.pesel,
          street: response.data.street,
          postal_code: response.data.postal_code,
          city: response.data.city,
          phone_number: response.data.phone_number,
          gender: response.data.gender,
          birthdate: response.data.birthdate,
          description: response.data.description,
        };
        store.dispatch(userActions.set(userData));

        return Promise.resolve({
          status: 200,
          message: 'Success',
        });
      } else if (response.status === 401) {
        deleteToken();
        store.dispatch(tokenActions.reset());

        return Promise.reject({
          status: 401,
          message: response.data.message,
        });
      } else {
        throw new Error('Troubles');
      }
    })
    .catch((e) => {
      deleteToken();

      return Promise.reject({
        status: e.status,
        message: e.message,
      });
    });

  return response;
};

export default user;
