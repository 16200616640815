import React, {useEffect, useState} from 'react';
import {push} from 'connected-react-router';
import {connect} from 'react-redux';
import {IUser} from '../../redux/user/types';
import {Accordion, Button, Card, Col, Row} from 'react-bootstrap';
import users from '../../adapters/user/users';
import AddUserForm from '../../components/Forms/AddUserForm';
import ChangePassword from '../../components/Forms/ChangePassword';
import Confirm from '../../components/Confirm/Confirm';
import deleteUser from '../../adapters/user/deleteUser';

const Users = (props: any) => {
	const [usersData, setUsersData] = useState<IUser[]>(new Array());
	const [confirm, setConfirm] = useState<undefined | JSX.Element>(undefined);

	useEffect(() => {
		if (usersData.length === 0) {
			users()
				.then((response) => {
					setUsersData(response as IUser[]);
				})
				.catch(() => {
					props.push('/');
				});
		}

		return () => {};
	}, []);

	const handleConfirmedDelete = (id: number) => {
		deleteUser(id).then((res) => {
			setUsersData(usersData.filter((user) => user.id !== id));
		});
		setConfirm(undefined);
	};

	const handleDelete = (id: number | undefined) => {
		if (id) {
			setConfirm(
				<Confirm
					message='Czy napewno chcesz usunąć użytkownika?'
					onAccept={handleConfirmedDelete}
					onReject={(p: any) => {
						setConfirm(undefined);
					}}
					params={id}
				/>
			);
		}
	};

	return (
		<Row>
			<Col>
				<Accordion defaultActiveKey='0'>
					{usersData.map((user) => (
						<Card key={user.id} className='my-2'>
							<div className='w-100 d-flex justify-content-between align-items-center px-3 py-2'>
								<span>
									{user.lastname} {user.name} {user.email}
								</span>
								<Accordion.Toggle as={Button} eventKey={user.id.toString()}>
									Więcej
								</Accordion.Toggle>
							</div>
							<Accordion.Collapse eventKey={user.id.toString()}>
								<Card.Body className='px-5'>
									<Row className='py-2'>
										<button
											className='btn btn-outline-danger w-auto mx-auto mb-1 mx-auto'
											onClick={() => handleDelete(user.id)}
										>
											Usuń użytkownika
										</button>
									</Row>
									<AddUserForm
										user={user}
										title='Aktualizacja danych użytkownika'
										buttonText='Aktualizuj dane użytkownika'
									/>
									<Row className='pt-4'>
										<ChangePassword id={user.id} />
									</Row>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
					))}
				</Accordion>
				{confirm && confirm}
			</Col>
		</Row>
	);
};

export default connect(null, {push})(Users);
