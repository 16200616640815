import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Col, Form} from 'react-bootstrap';
import Select from 'react-select';
import addCode from '../../adapters/code/addCode';
import codes, {CodeType} from '../../adapters/code/getCodes';
import deleteCode from '../../adapters/code/deleteCode';
// TYPES
import {ICode, INewCode} from '../../types/codeInterface';
// STYLES
import './Codes.scss';

export interface ICodesProps {}

const Codes = (props: ICodesProps) => {
	const [currentCode, setCurrentCode] = useState<INewCode | undefined>(undefined);
	const [icdCodes, setIcdCodes] = useState<ICode[]>(new Array<ICode>());
	const [icfCodes, setIcfCodes] = useState<ICode[]>(new Array<ICode>());
	const [nfzCodes, setNfzCodes] = useState<ICode[]>(new Array<ICode>());
	const [isDuplicate, setIsDuplicate] = useState<boolean>(false);

	useEffect(() => {
		codes().then((res) => {
			const codes = res as {nfz: ICode[]; icd: ICode[]; icf: ICode[]};
			setIcdCodes(codes.icd as ICode[]);
			setIcfCodes(codes.icf as ICode[]);
			setNfzCodes(codes.nfz as ICode[]);
		});

		return () => {};
	}, []);

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();

		if (currentCode) {
			let canAdd = true;

			if (currentCode.type === CodeType.ICD) {
				const filtred = icdCodes.filter((code) => code.code === currentCode.code);

				if (filtred.length > 0) {
					setIsDuplicate(true);
					canAdd = false;
				} else {
					setIsDuplicate(false);
				}
			}

			if (currentCode.type === CodeType.ICF) {
				const filtred = icfCodes.filter((code) => code.code === currentCode.code);

				if (filtred.length > 0) {
					setIsDuplicate(true);
					canAdd = false;
				} else {
					setIsDuplicate(false);
				}
			}

			if (currentCode.type === CodeType.NFZ) {
				const filtred = nfzCodes.filter((code) => code.desc === currentCode.desc);

				if (filtred.length > 0) {
					setIsDuplicate(true);
					canAdd = false;
				} else {
					setIsDuplicate(false);
				}
			}

			if (canAdd) {
				addCode(currentCode)
					.then((res) => {
						////console.log(res);
						setCurrentCode(undefined);
						if (res.code && res.code.type == CodeType.ICD) {
							setIcdCodes([...icdCodes, res.code as ICode]);
						} else if (res.code && res.code.type == CodeType.ICF) {
							setIcfCodes([...icfCodes, res.code as ICode]);
						} else if (res.code) {
							setNfzCodes([...nfzCodes, res.code as ICode]);
						}
					})
					.catch((error) => console.log(error));
			}
		}
	};

	const handleDelete = (code: ICode) => {
		deleteCode(code.id).then((res) => {
			if (code.type === CodeType.ICD) {
				setIcdCodes(icdCodes.filter((item) => item.id !== code.id));
			} else if (code.type === CodeType.ICF) {
				setIcfCodes(icfCodes.filter((item) => item.id !== code.id));
			} else if (code.type === CodeType.NFZ) {
				setNfzCodes(nfzCodes.filter((item) => item.id !== code.id));
			}
		});
	};

	return (
		<div className='w-100 d-flex flex-wrap'>
			<div className='w-100'>
				<Card>
					<Card.Header as='h5'>Dodaj kod ICD/ICF</Card.Header>
					<Card.Body>
						<Form onSubmit={handleSubmit}>
							<div className='row d-flex justify-content-center'>
								<Col sm={4}>
									<Form.Group className='mb-3' controlId='formBasicPassword'>
										<Form.Label className='required'>Kod</Form.Label>
										<Form.Control
											type='text'
											min={2}
											max={12}
											value={currentCode ? currentCode.code : ''}
											required
											onChange={(e) =>
												currentCode
													? setCurrentCode({...currentCode, code: e.currentTarget.value})
													: setCurrentCode({code: e.currentTarget.value, desc: '', type: ''})
											}
										/>
									</Form.Group>
								</Col>
								<Col sm={4}>
									<Form.Group className='mb-3' controlId='formBasicPassword'>
										<Form.Label className='required'>Opis</Form.Label>
										<Form.Control
											type='text'
											min={2}
											max={12}
											value={currentCode ? currentCode.desc : ''}
											required
											onChange={(e) =>
												currentCode
													? setCurrentCode({...currentCode, desc: e.currentTarget.value})
													: setCurrentCode({desc: e.currentTarget.value, code: '', type: ''})
											}
										/>
									</Form.Group>
									<div className={`${isDuplicate ? '' : 'd-none'}`}>Kod już istnieje</div>
								</Col>
								<Col sm={4}>
									<Form.Group className='mb-3' controlId='formBasicPassword'>
										<Form.Label className='required'>Typ</Form.Label>
										<Select
											className='over__clendar'
											options={[
												{value: 'icd', label: 'ICD'},
												{value: 'icf', label: 'ICF'},
												{value: 'nfz', label: 'Procedura NFZ'},
											]}
											value={
												currentCode
													? {value: currentCode.type, label: currentCode.type.toUpperCase()}
													: {value: null, label: null}
											}
											onChange={(value) => {
												if (value?.value)
													currentCode
														? setCurrentCode({...currentCode, type: value.value})
														: setCurrentCode({type: value.value, code: '', desc: ''});
											}}
										/>
									</Form.Group>
								</Col>
							</div>

							<div className='w-100 d-flex justify-content-end'>
								<Button variant='success' type='submit' className='mt-4'>
									Dodaj kod
								</Button>
							</div>
						</Form>
					</Card.Body>
				</Card>
			</div>
			<div className='w-50 mt-4'>
				<strong>Kody ICD</strong>
				{icdCodes.map((code) => (
					<div className='pl-3 my-2'>
						{code.code} {code.desc}{' '}
						<button className='btn__delete' onClick={() => handleDelete(code)}>
							<i className='bi bi-trash-fill'></i>
						</button>
					</div>
				))}
			</div>
			<div className='w-50 mt-4'>
				<strong>Kody ICF</strong>
				{icfCodes.map((code) => (
					<div className='pl-3 my-2'>
						{code.code} {code.desc}
						<button className='btn__delete' onClick={() => handleDelete(code)}>
							<i className='bi bi-trash-fill'></i>
						</button>
					</div>
				))}
			</div>
			<div className='w-50 mt-4'>
				<strong>Procedury NFZ</strong>
				{nfzCodes.map((code) => (
					<div className='pl-3 my-2'>
						{code.code} {code.desc}
						<button className='btn__delete' onClick={() => handleDelete(code)}>
							<i className='bi bi-trash-fill'></i>
						</button>
					</div>
				))}
			</div>
		</div>
	);
};

export default Codes;
