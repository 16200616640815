import React from 'react';
import DayColumn from './DayColumn';
import './Calendar.scss';
import weekDays from './helpers/day';
import defaultSchedules from '../../types/defaults/schedulesArray';
import IVisit from '../../types/visitInterface';
import {ISchedule} from '../../types/SheduleInterface';
import {TaskController} from './prepareVisits';

export interface ICalendarProps {
	data: any;
	handleClick: any;
	timeInterval: number;
	beginTimeOffset: number;
	endTimeOffset: number;
	step: number;
	startDate: Date;
	//   tasks: ITasksList[];
	userId: number;
	dateVisits: any;
	schedules: ISchedule[];
	showHours: boolean;
}

const Calendar = (props: ICalendarProps) => {
	const stepsCount =
		24 * Math.floor(60 / props.step) -
		Math.floor(props.beginTimeOffset / props.step) -
		Math.floor(props.endTimeOffset / props.step);
	const baseItemHeight = 30 * (props.timeInterval / props.step);

	//   const getTaskByDay = (date: Date): ITasksList => {
	//     let calendarTask = {
	//       day: date.getDay(),
	//       task: new CalendarTask({
	//         user_id: props.userId,
	//         day: date.getDay(),
	//         start_date: '',
	//         end_date: '',
	//         start_time: '0',
	//         end_time: '0',
	//         visit_duration: 45,
	//         avaliable: false,
	//       }),
	//     };

	//     props.tasks.forEach((task) => {
	//       if (task.day === date.getDay() - 1 && task.task.isInDate(date)) {
	//         calendarTask = task;
	//       }
	//     });

	//     return calendarTask;
	//   };

	const getByDay = (date: string): ISchedule => {
		const schedules = props.schedules.filter((item) => item.day === new Date(date).getDay() - 1);

		if (schedules.length > 0) return schedules[0];

		return defaultSchedules(props.userId)[0];
	};

	const createHours = (): Array<JSX.Element> => {
		let hours = new Array<JSX.Element>();

		for (let i = 0; i < stepsCount / (props.timeInterval / props.step) + 1; i++) {
			hours.push(
				<div
					className='hours__item'
					style={{
						top: `${i * baseItemHeight}px`,
						height: `${baseItemHeight}px`,
					}}
					key={i}
				>
					<div className='item__hour'>
						{Math.floor((props.beginTimeOffset + i * props.timeInterval) / 60)}
					</div>
					<div className='item__minutes'>
						{((props.beginTimeOffset + i * props.timeInterval) % 60).toString().padStart(2, '0')}
					</div>
				</div>
			);
		}

		return hours;
	};

	const getColumns = () => {
		const columns = new Array();

		for (const [key, value] of Object.entries(props.dateVisits)) {
			const taskController = new TaskController(getByDay(key), value as IVisit[]);
			//console.log(taskController.tasks);
			columns.push(
				<DayColumn
					date={key}
					handleClick={props.handleClick}
					userId={props.userId}
					tasks={taskController.tasks}
				/>
			);
		}

		//console.log(props.dateVisits);
		return columns;
	};

	return (
		<div className={`calendar ${Object.entries(props.dateVisits).length <= 1 ? 'single' : ''}`}>
			{props.showHours && <div className='calendar__hours'>{createHours()}</div>}
			{getColumns()}
		</div>
	);
};

export default Calendar;
