import axios from '../xhr';
import { ISchedule } from '../../types/SheduleInterface';

export interface IResponse {
  status: number;
  message: string;
}

const schedules = (id?: number): Promise<IResponse | ISchedule[]> => {
  const response = axios
    .get(`/getSchedule/${id}`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      } else if (response.status === 401) {
        return Promise.reject({
          status: 401,
          message: response.data.message,
        });
      } else {
        throw new Error('Troubles');
      }
    })
    .catch((e) => {
      return Promise.reject({
        status: e.status,
        message: e.message,
      });
    });

  return response;
};

export default schedules;
