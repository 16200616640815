const SET = 'SET_USER';
const RESET = 'DELETE_USER';

export interface IUser {
	id: number;
	name: string;
	email: string;
	permission: string;
	lastname: string;
	pesel: string;
	street: string;
	postal_code: string;
	city: string;
	phone_number: string;
	gender: string;
	birthdate: string;
	description: string;
	additional?: {
		color?: string;
	};
}

const types = {
	SET,
	RESET,
};

export default types;
