import types from './types';
import { AnyAction } from 'redux';
import defaultUser from '../../helpers/defaultUser';

const userReducer = (state = defaultUser, action: AnyAction) => {
  switch (action.type) {
    case types.SET:
      return {
        ...action.user,
      };
    case types.RESET:
      return {
        ...defaultUser,
      };
    default:
      return state;
  }
};

export default userReducer;
