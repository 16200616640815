export const isToken = (): boolean => {
	window.addEventListener('storage', () => {
		//console.log('called storage event');
	});

	return sessionStorage.getItem('token') !== null;
};

export const deleteToken = () => {
	sessionStorage.removeItem('token');
};

export const setToken = (token: string) => {
	sessionStorage.setItem('token', token);
};
