import React from 'react';
import './Confirm.scss';

export interface IConfirmProps {
  message: string;
  onAccept: any;
  onReject: any;
  params: any;
}

const Confirm = (props: IConfirmProps) => {
  return (
    <div className='confirm'>
      <div className='confirm__message'>{props.message}</div>
      <div className='d-flex justify-content-between'>
        <button className='btn btn-danger px-5' onClick={() => props.onReject(props.params)}>
          Nie
        </button>
        <button className='btn btn-success px-5' onClick={() => props.onAccept(props.params)}>
          Tak
        </button>
      </div>
    </div>
  );
};

export default Confirm;
