import {IVisitsRaport} from '../../pages/VisitsRaport/VisitsRaport';
import axios from '../xhr';

export interface IResponse {
	status: number;
	message: string;
}

const visits = (data: {
	date_start: string;
	date_end: string;
	user_id?: number;
}): Promise<IResponse | IVisitsRaport[]> => {
	const response = axios
		.post(`/raport/visits`, data)
		.then((response) => {
			if (response.status === 200) {
				return Promise.resolve(response.data.data);
			} else if (response.status === 401) {
				return Promise.reject({
					status: 401,
					message: response.data.message,
				});
			} else {
				throw new Error('Troubles');
			}
		})
		.catch((e) => {
			//console.log(e);
			return Promise.reject({
				status: e.status,
				message: e.message,
			});
		});

	return response;
};

export default visits;
