const SET = 'SET_CALENDAR_DATE';
const RESET = 'RESET_CALENDAR_DATE';

export interface ICalendarDate {
	date: Date;
}

const types = {
	SET,
	RESET,
};

export default types;
