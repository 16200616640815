import types, {ICalendarDate} from './types';
import {AnyAction} from 'redux';

const initialState: ICalendarDate = {
  date: new Date(),
};

const dateReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.SET:
      return {
        date: new Date(action.date),
      };
    case types.RESET:
      return {
        date: new Date(),
      };
    default:
      return state;
  }
};

export default dateReducer;
