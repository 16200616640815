import React, {ChangeEvent, useRef, useState} from 'react';
import FormMessage from './FormMessage';
import {Button, Card, Col, Form, InputGroup, Row} from 'react-bootstrap';
import PeselDecoder from '../../helpers/peselDecoder';
import IPatient from '../../types/patientInterface';
import {patient} from '../../types/defaults/patient';
// MODULES
import addPatient, {IRegisterPatientResponse} from '../../adapters/patient/addPatient';
import updatePatient from '../../adapters/patient/updatePatient';
import {notificationActions} from '../../redux/notifications/index';
import {connect} from 'react-redux';

// STYLES
import './AddPatientForm.scss';

export interface IAddUserFormProps {
  patient?: IPatient;
  isPopUp: boolean;
  closeHandler?: any;
  addedPatient?: any;
  addNotification: any;
}

const AddPatientForm = (props: IAddUserFormProps) => {
  const defaultState = props.patient ? props.patient : patient;
  const [state, setState] = useState<IPatient>({...defaultState});
  const formRef = useRef<HTMLFormElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...(state as IPatient),
      [event.currentTarget.name]: event.currentTarget.value,
    });

    if (event.currentTarget.name === 'pesel') {
      let peselDecoder = new PeselDecoder(event.currentTarget.value);

      if (peselDecoder.validate()) {
        event.currentTarget.setCustomValidity('');
        setState({
          ...(state as IPatient),
          gender: peselDecoder.getGender(),
          birthdate: peselDecoder.getDate(),
          [event.currentTarget.name]: event.currentTarget.value,
        });
      } else {
        event.currentTarget.setCustomValidity('PESEL jest nie poprawny');
        if (formRef.current) {
          formRef.current.reportValidity();
        }
      }
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    if (state) {
      if (!state.id) {
        addPatient(state as IPatient)
          .then((resp: IRegisterPatientResponse) => {
            props.addNotification(resp.message);

            if (props.addedPatient) {
              props.addedPatient(resp.patient);

              if (props.closeHandler) props.closeHandler(true);
            }
          })
          .catch((e) => props.addNotification(e.message));
      } else {
        let userData: any = state;
        delete userData.password;

        updatePatient(userData as IPatient)
          .then((resp: IRegisterPatientResponse) => {
            props.addNotification(resp.message);
          })
          .catch((e) => props.addNotification(e.message));
      }
    }
    e.preventDefault();
  };

  return (
    <Row>
      <Card className={props.isPopUp ? 'popup' : ''}>
        <Card.Header as='h5'>
          {props.patient ? `${props.patient.lastname} ${props.patient.name}` : 'Dodaj pacjenta'}
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit} ref={formRef}>
            <Row>
              <Col sm={6}>
                <Form.Group controlId='formBasicPassword'>
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control
                    type='email'
                    placeholder='E-mail'
                    autoComplete='new-email'
                    name='email'
                    onChange={(event) => handleChange(event as any)}
                    value={state.email}
                  />
                </Form.Group>
                <Form.Group controlId='formBasicPassword'>
                  <Form.Label className='required'>Imię</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Imię'
                    name='name'
                    onChange={(event) => handleChange(event as any)}
                    value={state.name}
                    required
                  />
                </Form.Group>
                <Form.Group controlId='formBasicPassword'>
                  <Form.Label className='required'>Nazwisko</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Nazwisko'
                    name='lastname'
                    onChange={(event) => handleChange(event as any)}
                    value={state.lastname}
                    required
                  />
                </Form.Group>
                <Form.Group controlId='formBasicPassword'>
                  <Form.Label>PESEL</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='11223344556'
                    name='pesel'
                    pattern='\d{11}'
                    minLength={11}
                    maxLength={11}
                    onChange={(event) => handleChange(event as any)}
                    value={state.pesel}
                  />
                </Form.Group>
                <Form.Group controlId='formBasicPassword'>
                  <Form.Label>Ulica, nr. domu/lokalu</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Ulica, nr. domu/lokalu'
                    name='street'
                    onChange={(event) => handleChange(event as any)}
                    value={state.street}
                  />
                </Form.Group>
                <Form.Group controlId='formBasicPassword'>
                  <Form.Label>Kod pocztowy</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='12-123'
                    name='postal_code'
                    pattern='\d{2}[-]\d{3}'
                    data-inputmask="'mask': ''"
                    onChange={(event) => handleChange(event as any)}
                    value={state.postal_code}
                  />
                </Form.Group>
                <Form.Group controlId='formBasicPassword'>
                  <Form.Label>Miasto</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Miasto'
                    name='city'
                    onChange={(event) => handleChange(event as any)}
                    value={state.city}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId='formBasicPassword'>
                  <Form.Label className='required'>Numer telefonu</Form.Label>
                  <Form.Control
                    type='tel'
                    placeholder='+48 444 000 555'
                    name='phone_number'
                    onChange={(event) => handleChange(event as any)}
                    value={state.phone_number}
                    pattern='^(([+]\d{1,2})\d{9})|(\d{9})'
                    required
                  />
                </Form.Group>
                <Form.Group className='py-2'>
                  <Form.Label>Płeć</Form.Label>
                  <Form.Check
                    type='radio'
                    label={`mężczyzna`}
                    name='gender'
                    value='male'
                    onChange={(event) => handleChange(event as any)}
                    checked={state.gender === 'male'}
                  />
                  <Form.Check
                    type='radio'
                    label={`kobieta`}
                    name='gender'
                    value='female'
                    onChange={(event) => handleChange(event as any)}
                    checked={state.gender === 'female'}
                  />
                </Form.Group>
                <Form.Group controlId='formBasicPassword'>
                  <Form.Label>Data urodzenia</Form.Label>
                  <Form.Control
                    type='date'
                    name='birthdate'
                    onChange={(event) => handleChange(event as any)}
                    value={state.birthdate}
                  />
                </Form.Group>
                <Form.Group controlId='exampleForm.ControlTextarea1'>
                  <Form.Label>Opis</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={5}
                    name='description'
                    onChange={(event) => handleChange(event as any)}
                    value={state.description}
                  />
                </Form.Group>
              </Col>
              <Col sm={12}>
                <div className={`w-100 d-flex ${props.isPopUp ? 'justify-content-between' : 'justify-content-end'}`}>
                  {props.isPopUp && (
                    <Button
                      variant='outline-success'
                      name='close'
                      onClick={() => props.closeHandler(true)}
                      className='mt-4'
                    >
                      Zamknij
                    </Button>
                  )}
                  <Button variant='success' type='submit' className='mt-4'>
                    {props.patient ? 'Zaktualizuj' : 'Dodaj'} pacjenta
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Row>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addNotification: (payload: string) => dispatch(notificationActions.add(payload)),
  };
};

export default connect(null, mapDispatchToProps)(AddPatientForm);
