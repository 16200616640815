import React, {useEffect, useState} from 'react';
import {Button, Card, Form} from 'react-bootstrap';
// MODULES
import patientsSearch from '../../adapters/patient/getPatientsSearch';
import {connect} from 'react-redux';
import addVisit from '../../adapters/visit/addVisit';
import visitById from '../../adapters/visit/getById';
import deleteVisit from '../../adapters/visit/deleteVisit';
import date from '../Calendar/helpers/date';
import updateVisit from '../../adapters/visit/updateVisit';
import moment from 'moment';
import {notificationActions} from '../../redux/notifications/index';
import getUsers from '../../adapters/user/users';
// TYPES
import IPatient from '../../types/patientInterface';
import IVisit from '../../types/visitInterface';
import defaultVisit from '../../types/defaults/visit';
import {IUser} from '../../redux/user/types';
// COMPONENTS
import AddPatientForm from '../Forms/AddPatientForm';
import TimePicker from 'rc-time-picker';
import Select from 'react-select';
import SelectSearch from 'react-select/async';
import AddTreatment from '../AddTreatment/AddTreatment';
import Confirm from '../../components/Confirm/Confirm';
// STYLES
import 'rc-time-picker/assets/index.css';
import './AddVisit.scss';

export interface IAddVisitProps {
	onClick: any;
	userId: number;
	visitId?: number;
	date: Date;
	duration: number;
	start: string;
	addNotification: any;
	userPermissions: string;
	isUserSelection?: boolean;
	treatmentId?: number;
	onAdd?: any;
	patientId?: number;
}

const AddVisit = (props: IAddVisitProps) => {
	const [patients, setPatients] = useState<IPatient[] | null>(null);
	const [visit, setVisit] = useState<IVisit>({
		...defaultVisit,
		user_id: props.userId,
		date: date.dateToString(props.date),
		visit_duration: props.duration,
		start_time: props.start,
	});
	const [addPatient, setAddPatient] = useState<null | JSX.Element>(null);
	const [isVisitsLoading, setIsVisitsLoading] = useState(true);
	const [isPatientsLoading, setIsPatientsLoading] = useState(true);
	const [windowOffset, setWindowOffset] = useState(props.isUserSelection ? 0 : window.pageYOffset + 100);
	const [addTreatment, setAddTreatment] = useState<null | JSX.Element>(null);
	const [users, setUsers] = useState<IUser[]>(new Array<IUser>());
	const [confirm, setConfirm] = useState<undefined | JSX.Element>(undefined);

	useEffect(() => {
		//console.log(visit);
		return () => {};
	}, [visit]);

	useEffect(() => {
		let visitExt = {...visit};
		if (props.treatmentId) {
			visitExt = {...visitExt, treatment_id: props.treatmentId};
		}

		if (props.patientId) {
			visitExt = {...visitExt, patient_id: props.patientId};
		}

		if (props.patientId || props.treatmentId) setVisit(visitExt);

		// getPatients()
		// 	.then((result) => {
		// 		setPatients(result as IPatient[]);
		setIsPatientsLoading(false);

		if (props.visitId) {
			visitById(props.visitId)
				.then((res) => {
					setVisit(res as IVisit);
					setIsVisitsLoading(false);
				})
				.catch((e) => {
					//console.log(e);
					setIsVisitsLoading(false);
				});
		} else {
			setIsVisitsLoading(false);
		}
		// })
		// .catch((e) => {
		// 	//console.log(e);
		// 	setIsPatientsLoading(false);
		// });

		if (props.isUserSelection) {
			getUsers()
				.then((response) => {
					setUsers(response as IUser[]);
				})
				.catch(() => {});
		}
	}, []);

	const handleClick = (close?: boolean) => {
		if (close) {
			setAddPatient(null);
		} else {
			setAddPatient(<AddPatientForm addedPatient={handleNewPatient} isPopUp={true} closeHandler={handleClick} />);
		}
	};

	const handleNewPatient = (patient: IPatient) => {
		const patientsArray = patients ? [...patients] : new Array<IPatient>();
		setPatients([...patientsArray, patient]);
		//console.log(patient);
		if (patient.id) setVisit({...visit, patient_id: patient.id});
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.currentTarget.type === 'checkbox') {
			setVisit({
				...visit,
				[event.currentTarget.name]: event.currentTarget.checked,
			});
		} else {
			setVisit({
				...visit,
				[event.currentTarget.name]: event.currentTarget.value,
			});
		}
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		if (visit.id) {
			updateVisit({
				...visit,
				visit_duration: Number(visit.visit_duration),
				patient_id: Number(visit.patient_id),
				user_id: props.userId,
			})
				.then((response) => {
					//console.log(response.message);
					props.addNotification(response.message);
					props.onClick(true);
				})
				.catch((e) => props.addNotification(e.message));
		} else {
			//console.log(visit);
			addVisit({
				...visit,
				visit_duration: Number(visit.visit_duration),
				patient_id: Number(visit.patient_id),
			})
				.then((response) => {
					//console.log(response.message);
					props.addNotification(response.message);
					props.onClick(true);
					if (props.onAdd) {
						props.onAdd(response.visit);
					}
				})
				.catch((e) => props.addNotification(e.message));
		}
	};

	const handleConfirmedDelete = (id: number | undefined) => {
		if (visit.id) {
			deleteVisit(visit.id)
				.then((res) => {
					props.addNotification('Wizyta została pomyślnie usunięta.');
					setConfirm(undefined);
					props.onClick(true);
				})
				.catch((e) => {
					props.addNotification('Nie udało się usunąć wizyty.');
					setConfirm(undefined);
				});
		}
	};

	const handleDelete = (id: number | undefined) => {
		if (id) {
			setConfirm(
				<Confirm
					message='Czy napewno chcesz usunąć wizytę?'
					onAccept={handleConfirmedDelete}
					onReject={(p: any) => {
						setConfirm(undefined);
					}}
					params={id}
				/>
			);
		}
	};

	const getSelectCurrentValue = () => {
		if (patients) {
			const matchedPatients = patients.filter((patient) => patient.id === visit.patient_id);

			if (matchedPatients.length > 0) {
				return {
					value: matchedPatients[0].id,
					label: `${matchedPatients[0].lastname} ${matchedPatients[0].name}`,
				};
			}
		}
	};

	const startVisit = () => {
		setAddTreatment(<AddTreatment visitId={visit.id} close={hideStartVisit} patientId={visit.patient_id} />);
	};

	const hideStartVisit = () => {
		setAddTreatment(null);
	};

	const handleUserChange = (id: number) => {
		if (users) {
			const selected = users.filter((user) => user.id === id);

			if (selected.length > 0) {
				setVisit({...visit, user_id: selected[0].id});
			}
		}
	};

	const getSelectUser = () => {
		const user = users.filter((user) => user.id === visit.user_id);

		if (user.length > 0) {
			return {value: user[0].id, label: `${user[0].lastname} ${user[0].name} ${user[0].email}`};
		} else {
			return {value: '', label: ``};
		}
	};

	const [searchTimeout, setSearchTimeout] = useState<null | ReturnType<typeof setTimeout>>(null);

	const debounce = (input: string, callback: any) => {
		if (input.length >= 2) {
			if (searchTimeout) {
				clearTimeout(searchTimeout);
			}

			setSearchTimeout(
				setTimeout(() => {
					patientsSearch(input).then((res) => {
						callback(
							(res as IPatient[]).map((item) => {
								return {label: `${item.lastname} ${item.name}`, value: item.id};
							})
						);
					});
				}, 600)
			);
		} else {
			if (searchTimeout) {
				clearTimeout(searchTimeout);
			}
		}
	};

	return (
		<Card
			className={`add-visit position-absolute w-75 popup ${
				isVisitsLoading || isPatientsLoading ? 'loading' : ''
			}`}
			style={{zIndex: 20, top: windowOffset}}
		>
			<Card.Header>Dodaj wizytę</Card.Header>
			<Card.Body>
				<Form onSubmit={handleSubmit}>
					{!props.patientId && (
						<Form.Group className='d-flex flex-wrap'>
							<Form.Label className='w-100'>Wybierz pacjenta</Form.Label>

							<SelectSearch
								loadOptions={debounce}
								value={getSelectCurrentValue()}
								onChange={(value) => {
									if (value?.value) setVisit({...visit, patient_id: value?.value});
								}}
								isSearchable
								className='w-75'
							/>
							<button
								type='button'
								className='btn btn-outline-success w-25'
								onClick={() => handleClick()}
							>
								Dodaj pacjenta
							</button>
						</Form.Group>
					)}
					{visit.phone_number && <div className='my-2'>Nr. tel. pacjenta: {visit.phone_number}</div>}
					{props.isUserSelection && (
						<Form.Group className='mb-5'>
							<Form.Label>Wybierz fizjoterapętę</Form.Label>
							<Select
								className='over__clendar'
								options={users
									?.filter((user) => user.permission !== 'recepcja')
									.map((user) => {
										return {value: user.id, label: `${user.lastname} ${user.name}  ${user.email}`};
									})}
								value={getSelectUser()}
								onChange={(value) => {
									if (value?.value) handleUserChange(Number(value.value));
								}}
								isSearchable
							/>
						</Form.Group>
					)}
					<Form.Group controlId='formBasicPassword'>
						<Form.Label className='required'>Data wizyty</Form.Label>
						<Form.Control type='date' name='date' onChange={handleChange} value={visit.date} required />
					</Form.Group>
					<Form.Group controlId='formBasicPassword'>
						<Form.Label>Od</Form.Label>
						<TimePicker
							value={moment(visit.start_time.slice(0, 5), 'HH:mm')}
							minuteStep={5}
							onChange={(value) => {
								setVisit({...visit, start_time: value.format('HH:mm')});
							}}
							showSecond={false}
							className='w-100'
							allowEmpty={false}
						/>
					</Form.Group>
					<Form.Group controlId='formBasicPassword'>
						<Form.Label className='required'>Czas trwania</Form.Label>
						<Form.Control
							type='number'
							placeholder='45'
							name='visit_duration'
							value={visit.visit_duration}
							onChange={handleChange}
							step={5}
							required
						/>
					</Form.Group>
					<Form.Group controlId='exampleForm.ControlTextarea1'>
						<Form.Label>Opis</Form.Label>
						<Form.Control
							as='textarea'
							rows={4}
							name='description'
							onChange={handleChange}
							value={visit.description}
						/>
					</Form.Group>
					<Form.Group controlId='custom-switch' className='d-flex align-items-center mt-3'>
						<Form.Check
							type='switch'
							id='custom-switch'
							checked={visit.confirmed}
							onChange={handleChange}
							name='confirmed'
						/>
						<Form.Label className='mx-3 d-block'>Pacjent potwierdził wizytę</Form.Label>
					</Form.Group>
					<Form.Group controlId='custom-switch-3' className='d-flex align-items-center mt-3 w-100'>
						<Form.Check
							type='switch'
							id='custom-switch-3'
							checked={visit.consultations}
							onChange={handleChange}
							name='consultations'
						/>
						<Form.Label className='mx-3 d-block'>Konsultacja</Form.Label>
					</Form.Group>
					<div className='d-flex justify-content-between align-items-center'>
						<div className='mt-4'>
							<button
								name='close'
								type='button'
								className='btn btn-outline-primary mx-2'
								onClick={() => {
									props.onClick(true);
								}}
							>
								Zamknij
							</button>

							{visit.id && (
								<button
									name='close'
									className='btn btn-outline-danger mx-2'
									onClick={() => handleDelete(visit.id)}
									type='button'
								>
									Usuń
								</button>
							)}
							{visit.id && props.userPermissions !== 'recepcja' && (
								<button
									name='start'
									className='btn btn-outline-success mx-2'
									onClick={(e) => {
										e.preventDefault();
										startVisit();
									}}
									type='button'
								>
									Rozpocznij
								</button>
							)}
						</div>
						<Button variant='success' type='submit' className='mt-4 mx-2'>
							Zapisz
						</Button>
					</div>
				</Form>
				{confirm && confirm}
			</Card.Body>
			{addPatient && addPatient}
			{addTreatment && addTreatment}
		</Card>
	);
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addNotification: (payload: string) => dispatch(notificationActions.add(payload)),
	};
};

export default connect(null, mapDispatchToProps)(AddVisit);
