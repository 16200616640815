import React, {useEffect, useState} from 'react';
import ReactPDF, {PDFDownloadLink} from '@react-pdf/renderer';
import Raport from '../../components/Raport/Raport';
import visits from '../../adapters/raports/visits';
import {Button, Card, Col, Form} from 'react-bootstrap';
import {IUser} from '../../redux/user/types';
import getUsers from '../../adapters/user/users';
import Select from 'react-select';

import './VisitsRaport.scss';
import {number} from 'joi';

export interface IVisitsRaport {
  sum: number;
  sum_time: number;
  count: number;
  count_done: number;
  visits: [
    {
      name: string;
      lastname: string;
      date: string;
      start_time: string;
      price: number;
      done: boolean;
      visit_duration: number;
    }
  ];
  user: {
    id: number;
    name: string;
    lastname: string;
  };
}

const timeToString = (time: number): string => {
  return `${Math.floor(time / 60)}:${(time % 60).toString().padStart(2, '0')}`;
};

const VisitsRaport = () => {
  const [raportData, setRaportData] = useState<IVisitsRaport[]>([]);
  const [users, setUsers] = useState<IUser[]>(new Array());
  const [selectedUser, setSelectedUser] = useState<IUser | undefined>();
  const [date, setDate] = useState<{
    date_start: string | undefined;
    date_end: string | undefined;
    user_id: number | undefined;
  }>({
    date_end: undefined,
    date_start: undefined,
    user_id: undefined,
  });

  useEffect(() => {
    getUsers()
      .then((response) => {
        setUsers(response as IUser[]);
      })
      .catch(() => {});
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (date.date_start && date.date_end) {
      setRaportData([]);

      visits(date as {date_start: string; date_end: string})
        .then((result) => {
          setRaportData(result as IVisitsRaport[]);
          console.log(result);
        })
        .catch((e) => {});
    }
  };

  const getSelectCurrentValue = () => {
    if (selectedUser) {
      return {
        value: selectedUser.id,
        label: `${selectedUser.lastname} ${selectedUser.name} ${selectedUser.email}`,
      };
    }
  };

  return (
    <div>
      <Card>
        <Card.Header as='h5'>Raport z wizyt</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <div className='row d-flex justify-content-center align-items-end'>
              <Col sm={3}>
                <Form.Group className='mb-3' controlId='formBasicPassword'>
                  <Form.Label className='required'>Od</Form.Label>
                  <Form.Control
                    type='date'
                    placeholder='rrrr-mm-dd'
                    name='start_date'
                    value={date.date_start}
                    onChange={(event) => {
                      setDate({...date, date_start: event.target.value});
                    }}
                    max={date.date_end}
                    required
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group className='mb-3' controlId='formBasicPassword'>
                  <Form.Label className='required'>Do</Form.Label>
                  <Form.Control
                    type='date'
                    placeholder='rrrr-mm-dd'
                    name='end_date'
                    value={date.date_end}
                    onChange={(event) => {
                      setDate({...date, date_end: event.target.value});
                    }}
                    min={date.date_start}
                    required
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group className='mb-3'>
                  <Form.Label>Wybierz fizjoterapętę</Form.Label>
                  <Select
                    className='over__clendar'
                    options={[
                      {
                        value: -1,
                        label: `Wszyscy`,
                      },
                      ...users
                        ?.filter((user) => user.permission !== 'recepcja')
                        .map((user) => {
                          return {
                            value: user.id,
                            label: `${user.lastname} ${user.name}  ${user.email}`,
                          };
                        }),
                    ]}
                    value={getSelectCurrentValue()}
                    onChange={(value) => {
                      if (value?.value) {
                        if (users) {
                          const selected = users.filter((user) => user.id === value.value);

                          setSelectedUser(selected.length > 0 ? selected[0] : undefined);
                          setDate({
                            ...date,
                            user_id: selected.length > 0 ? selected[0].id : undefined,
                          });
                        }
                      }
                    }}
                    isSearchable
                  />
                </Form.Group>
              </Col>
              <Col sm={3} className='d-flex justify-content-end'>
                <Button variant='success' type='submit' className='mb-3'>
                  Generuj raport
                </Button>
              </Col>
            </div>

            <div className='w-100 d-flex justify-content-end'></div>
          </Form>
        </Card.Body>
      </Card>
      {raportData.length > 0 && (
        <Card className='mt-4'>
          <Card.Header as='h5'>Raport</Card.Header>
          <Card.Body>
            <div className='row d-flex row-header'>
              <Col sm={3}>Fizjoterapeuta</Col>
              <Col sm={2}>Wartość wizyt</Col>
              <Col sm={2}>Ilość wszystkich wizyt</Col>
              <Col sm={2}>Ilość odbytych wizyt</Col>
              <Col sm={2}>Czas odbytych wizyt</Col>
            </div>
            {raportData.map((item, index) => (
              <div className='row d-flex row-data' key={index}>
                <Col sm={3}>
                  {item.user.lastname} {item.user.name}
                </Col>
                <Col sm={2}>{Number(item.sum).toFixed(2)} zł</Col>
                <Col sm={2}>{item.count}</Col>
                <Col sm={2}>{item.count_done}</Col>
                <Col sm={2}>{timeToString(Number(item.sum_time))}</Col>
              </div>
            ))}
            <div className='row d-flex row-sum'>
              <Col sm={3}>Podsumowanie:</Col>
              <Col sm={2}>
                {raportData
                  .reduce((partialSum: number, item: IVisitsRaport) => partialSum + Number(item.sum), 0)
                  .toFixed(2)}{' '}
                zł
              </Col>
              <Col sm={2}>
                {raportData.reduce((partialSum: number, item: IVisitsRaport) => partialSum + Number(item.count), 0)}
              </Col>
              <Col sm={2}>
                {raportData.reduce(
                  (partialSum: number, item: IVisitsRaport) => partialSum + Number(item.count_done),
                  0
                )}
              </Col>
              <Col sm={2}>
                {timeToString(
                  raportData.reduce((partialSum: number, item: IVisitsRaport) => partialSum + Number(item.sum_time), 0)
                )}
              </Col>
            </div>

            <div className='row d-flex mt-5 row-header'>
              <Col sm={2}>Fizjoterapeuta</Col>
              <Col sm={2}>Pacjent</Col>
              <Col sm={2}>Data wizyty</Col>
              <Col sm={2}>Godzina wizyty</Col>
              <Col sm={1}>Czas trwania</Col>
              <Col sm={2}>Cena</Col>
              <Col sm={1}>Odbyta</Col>
            </div>
            {raportData.map((item, index) => {
              return item.visits.map((visit) => (
                <div className='row d-flex row-data' key={index}>
                  <Col sm={2}>
                    {item.user.lastname} {item.user.name}
                  </Col>
                  <Col sm={2}>
                    {visit.lastname} {visit.name}
                  </Col>
                  <Col sm={2}>{visit.date}</Col>
                  <Col sm={2}>{visit.start_time.substr(0, 5)}</Col>
                  <Col sm={1}>{timeToString(Number(visit.visit_duration))}</Col>
                  <Col sm={2}>{Number(visit.price).toFixed(2)} zł</Col>
                  <Col sm={1} className={`${visit.done ? '' : 'color__important weight__bold'}`}>
                    {visit.done ? 'Tak' : 'Nie'}
                  </Col>
                </div>
              ));
            })}
            {/* <PDFDownloadLink
              className='d-block mt-4 btn btn-primary w-25'
              document={<Raport raportData={raportData} />}
            >
              Pobierz PDF
            </PDFDownloadLink> */}
          </Card.Body>
        </Card>
      )}
      {/* <ReactPDF.PDFViewer className='w-100' style={{height: '500px'}}>
				<Raport />
			</ReactPDF.PDFViewer> */}

      {/* {raportData.length > 0 && (
        <PDFDownloadLink document={<Raport raportData={raportData} />}> Pobierz</PDFDownloadLink>
      )} */}
    </div>
  );
};

export default VisitsRaport;
