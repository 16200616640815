import types, {INotification} from './types';

const add = (message: string) => ({
  type: types.ADD,
  message,
});

const change = (notification: INotification) => ({
  type: types.CHANGE,
  notification,
});

const reset = () => ({
  type: types.RESET,
});

const actions = {
  reset,
  add,
  change,
};

export default actions;
