import React, {useEffect, useState} from 'react';
import {push} from 'connected-react-router';
import {connect} from 'react-redux';
import {IUser} from '../../redux/user/types';
import {Accordion, Button, Card, Col, Row} from 'react-bootstrap';
import users from '../../adapters/user/users';
import SchedulesForm from '../../components/Forms/SchedulesForm';

const UsersSchedules = (props: any) => {
	const [usersData, setUsersData] = useState<IUser[]>();

	useEffect(() => {
		if (usersData === undefined) {
			users()
				.then((response) => {
					setUsersData(response as IUser[]);
				})
				.catch(() => {
					props.push('/');
				});
		}

		return () => {};
	}, []);

	return (
		<Row>
			<Col>
				<Accordion defaultActiveKey='0'>
					{usersData &&
						usersData
							?.filter((user) => user.permission !== 'recepcja')
							.map((user) => (
								<Card key={user.id} className='my-2'>
									<div className='w-100 d-flex justify-content-between align-items-center px-3 py-2'>
										<span>
											{user.lastname} {user.name} {user.email}
										</span>
										<Accordion.Toggle as={Button} eventKey={user.id.toString()}>
											Więcej
										</Accordion.Toggle>
									</div>
									<Accordion.Collapse eventKey={user.id.toString()}>
										<Card.Body className='px-5'>
											<Row className='pt-3'>
												<SchedulesForm user={user} />
											</Row>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
							))}
				</Accordion>
			</Col>
		</Row>
	);
};

export default connect(null, {push})(UsersSchedules);
