import React, {useEffect, useState} from 'react';
// MODULES
import schedules from '../../adapters/schedules/schedules';
import defaultSchedules from '../../types/defaults/schedulesArray';
import {connect} from 'react-redux';
import getVisit from '../../adapters/calendar/getInRange';
import dateHelper from '../../components/Calendar/helpers/date';
import getTreatmentById from '../../adapters/treatment/getTreatmentsById';
import {useHistory} from 'react-router-dom';
// TYPES
import {IUser} from '../../redux/user/types';
import {ISchedule} from '../../types/SheduleInterface';
import {ICalendarDate} from '../../redux/calendarDate/types';
import calendarDateTypes from '../../redux/calendarDate/types';
import IVisit from '../../types/visitInterface';
// COMPONENTS
import Calendar from '../../components/Calendar/Calendar';
import TestAlert from '../../components/Calendar/TestAlert';
import AddVisit from '../../components/AddVisit/AddVisit';
// STYLES
import './MyCalendar.scss';
import weekDays from '../../components/Calendar/helpers/day';

const MySchedules = (props: {
	user: IUser;
	calendarDate: ICalendarDate;
	setCalendarDate: any;
	isSingleDay?: boolean;
	hideHours?: boolean;
}) => {
	const [tasks, setTasks] = useState<ISchedule[]>(defaultSchedules(props.user.id));
	const [visits, setVisits] = useState(new Array<{[key: string]: IVisit[]}>());
	const [addVisit, setAddVisit] = useState<JSX.Element | null>(null);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const history = useHistory();

	// const minInterval = (tasks: ITasksList[]): number => {
	// 	let min = 15;

	// 	if (tasks.length > 0) {
	// 		min = tasks[0].task.interval;
	// 	}

	// 	tasks.forEach((task) => {
	// 		if (task.task.interval < min) min = task.task.interval;
	// 	});

	// 	return 30;
	// };

	//console.log(props.calendarDate);

	const handleClick = (
		close: boolean,
		taskData: {date: Date; duration: number; start: string},
		isDone: boolean,
		visitId?: number,
		visit?: IVisit
	) => {
		if (isDone && visit && visit.treatment_id) {
			getTreatmentById(visit?.treatment_id).then((response) => {
				history.push({
					pathname: '/treatment',
					state: {treatment: {...response}, patientId: visit.patient_id},
				});
			});
		} else {
			if (close) {
				setAddVisit(null);
				fetchData();
			} else {
				setAddVisit(
					<AddVisit
						date={taskData.date}
						duration={taskData.duration}
						start={taskData.start}
						userId={props.user.id}
						onClick={handleClick}
						visitId={visitId}
						userPermissions={props.user.permission}
					/>
				);
			}
		}
	};

	const handleWeekChange = (isForeward: boolean) => {
		const date = new Date(props.calendarDate.date);

		if (isForeward) {
			const nextWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7);
			props.setCalendarDate(nextWeek);
		} else {
			const lastWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7);
			props.setCalendarDate(lastWeek);
		}
	};

	const fetchData = () => {
		setIsLoaded(false);

		schedules(props.user.id)
			.then((response) => {
				//console.log(response);
				setTasks(response as ISchedule[]);
				getVisit(
					props.isSingleDay
						? [dateHelper.dateToString(props.calendarDate.date)]
						: weekDays(props.calendarDate.date).map((date) => dateHelper.dateToString(date)),
					props.user.id
				)
					.then((res) => {
						setVisits(res);
						setIsLoaded(true);
					})
					.catch((e) => {
						//console.log(e);
					});
			})
			.catch((e) => {
				setTasks(defaultSchedules(props.user.id));
				//console.log(e.message);
				setIsLoaded(true);
			});
	};

	useEffect(() => {
		fetchData();

		return () => {};
	}, [props.user, props.calendarDate.date]);
	return (
		<div
			className={`${isLoaded ? '' : 'loading'} d-flex flex-column`}
			style={{background: props.isSingleDay ? props.user.additional?.color : 'transparent'}}
		>
			{!props.isSingleDay && (
				<div className='w-100 d-flex justify-content-between'>
					<button onClick={() => handleWeekChange(false)} className='arrow-button'>
						<i className='bi bi-arrow-left-circle-fill'></i>
					</button>
					<button onClick={() => handleWeekChange(true)} className='arrow-button'>
						<i className='bi bi-arrow-right-circle-fill'></i>
					</button>
				</div>
			)}
			{props.isSingleDay && (
				<div className='mx-auto text-center mb-3 mt-2 weight__semi-bold px-1'>
					{props.user.lastname} {props.user.name}
				</div>
			)}
			<Calendar
				handleClick={handleClick}
				data='h1'
				// tasks={taskList(tasks)}
				timeInterval={30}
				beginTimeOffset={390}
				endTimeOffset={195}
				step={15}
				startDate={props.calendarDate.date}
				userId={props.user.id}
				dateVisits={visits}
				schedules={tasks}
				showHours={!props.hideHours}
			/>
			<div className='add-visit'>{addVisit && addVisit}</div>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	calendarDate: state.calendarDate,
});

const mapDispatchToProps = (dispatch: any) => {
	return {
		setCalendarDate: (payload: Date) => dispatch({type: calendarDateTypes.SET, date: payload}),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MySchedules);
