import {stat} from 'fs';
import React, {useState} from 'react';
import {Card, Form, Button} from 'react-bootstrap';
import changePassword, {IRegisterResponse} from '../../adapters/auth/changePassword';
import FormMessage from './FormMessage';

const ChangePassword = (props: {id: number}) => {
  const [state, setState] = useState({
    password: '',
    confirm: '',
  });
  const [message, setMessage] = useState<{
    message: string;
    errors: Object;
  }>({message: '', errors: Array<string>()});

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    if (state.password !== state.confirm) {
      setMessage({
        message: 'Hasło oraz potwierdzenie nie są takie same',
        errors: Array<string>(),
      });
    } else {
      setMessage({message: '', errors: Array<string>()});
      changePassword({id: props.id, password: state.password})
        .then((resp: IRegisterResponse) => {
          setMessage({
            message: resp.message,
            errors: resp.errors || message?.errors,
          });
          console.log(resp.message, resp.errors);
        })
        .catch((e) => console.log(e.message));
    }
    e.preventDefault();
  };

  return (
    <Card>
      <Card.Header as='h5'>Zmień hasło</Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId='formBasicPassword'>
            <Form.Label className='required'>Podaj nowe hasło</Form.Label>
            <Form.Control
              type='password'
              placeholder='Hasło'
              autoComplete='new-password'
              name='password'
              minLength={8}
              value={state.password}
              onChange={(event) => handleChange(event as any)}
              required
            />
          </Form.Group>
          <Form.Group controlId='formBasicPassword' className='mt-3'>
            <Form.Label className='required'>Potwierdź hasło</Form.Label>
            <Form.Control
              type='password'
              placeholder='Potwierdź hasło'
              autoComplete='new-password'
              name='confirm'
              value={state.confirm}
              onChange={(event) => handleChange(event as any)}
              required
            />
          </Form.Group>
          <div className='w-100 d-flex justify-content-end'>
            <Button variant='success' type='submit' className='mt-4'>
              Zmień hasło
            </Button>
          </div>
        </Form>
      </Card.Body>
      <FormMessage message={message.message} errors={message.errors} />
    </Card>
  );
};

export default ChangePassword;
