import { IUser } from '../redux/user/types';

const defaultUser: IUser = {
  id: -1,
  name: '',
  email: '',
  permission: 'fizjo',
  lastname: '',
  pesel: '',
  street: '',
  postal_code: '',
  city: '',
  phone_number: '',
  gender: 'male',
  birthdate: '',
  description: '',
};

export default defaultUser;
