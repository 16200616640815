import React, { useState } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { IUser } from '../../redux/user/types';
import ChangePassword from '../../components/Forms/ChangePassword';

import './Profile.scss';

export interface IProfileProps {
  user: IUser;
  push(path: string): any;
}

const Profile = (props: IProfileProps) => {
  React.useEffect(() => {
    if (props.user.id < 0) props.push('/login');
  }, [props.user.id]);

  return (
    <Row className='profile'>
      <Col>
        <Card>
          <Card.Header as='h5'>Dane</Card.Header>
          <Card.Body className='profile__data'>
            <div className='data__item'>
              <div className='item__name'>Imię</div>
              <div className='item__value'>{props.user.name}</div>
            </div>
            <div className='data__item'>
              <div className='item__name'>Nazwisko</div>
              <div className='item__value'>{props.user.lastname}</div>
            </div>
            <div className='data__item'>
              <div className='item__name'>Login</div>
              <div className='item__value'>{props.user.email}</div>
            </div>
            <div className='data__item'>
              <div className='item__name'>Rola</div>
              <div className='item__value'>{props.user.permission}</div>
            </div>
            <div className='data__item'>
              <div className='item__name'>PESEL</div>
              <div className='item__value'>{props.user.pesel}</div>
            </div>
            <div className='data__item'>
              <div className='item__name'>Ulica</div>
              <div className='item__value'>{props.user.street}</div>
            </div>
            <div className='data__item'>
              <div className='item__name'>Kod pocztowy</div>
              <div className='item__value'>{props.user.postal_code}</div>
            </div>
            <div className='data__item'>
              <div className='item__name'>Miasto</div>
              <div className='item__value'>{props.user.city}</div>
            </div>
            <div className='data__item'>
              <div className='item__name'>Numer telefonu</div>
              <div className='item__value'>{props.user.phone_number}</div>
            </div>
            <div className='data__item'>
              <div className='item__name'>Data urodzenia</div>
              <div className='item__value'>{props.user.birthdate}</div>
            </div>
            <div className='data__item'>
              <div className='item__name'>Płeć</div>
              <div className='item__value'>{props.user.gender}</div>
            </div>
            <div className='data__item'>
              <div className='item__name'>Opis</div>
              <div className='item__value'>{props.user.description}</div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <ChangePassword id={props.user.id} />
      </Col>
    </Row>
  );
};

export default connect(null, { push })(Profile);
