import {ISchedule} from './../../types/SheduleInterface';
import IVisit from '../../types/visitInterface';

export enum Types {
	Schedule,
	Visit,
	AdHock,
}

export interface ITask {
	start: number;
	end: number;
	type: Types;
	props: IVisit | undefined;
	duration: number;
}

export interface IPreTask {
	start: number;
	end: number;
	type: Types;
	props: IVisit | undefined;
	steps: number[];
}

export const CONSTS = {
	BASE_HEIGHT: 10,
	TIME_INTERVAL: 5,
	BEGIN_DAY_STEP: 390,
	END_DAY_STEP: 1260,
};

const prepareTasks = (visits: IVisit[], interval: number) => {};

// const prepareSchedules = (schedules)

export class TaskController {
	schedule: ISchedule;
	visits: IVisit[];
	visitTasks: IPreTask[];
	scheduleTasks: IPreTask[];
	adhockTasks: IPreTask[];
	tasks: TaskObj[];

	constructor(schedule: ISchedule, visits: IVisit[]) {
		this.schedule = schedule;
		this.visits = visits;
		this.visitTasks = new Array();
		this.scheduleTasks = new Array();
		this.adhockTasks = new Array();
		this.tasks = new Array();

		this.prepareAllTasks();
	}

	prepareAllTasks() {
		this.prepareVisitTasks();
		this.prepareScheduleTasks();
		this.prepareAdHockTasks();

		this.tasks = this.tasks.concat(
			this.adhockTasks.map(
				(task) =>
					new TaskObj({
						...task,
						duration: task.props ? task.props.visit_duration : this.schedule.visit_duration,
					})
			)
		);
		this.tasks = this.tasks.concat(
			this.scheduleTasks.map(
				(task) =>
					new TaskObj({
						...task,
						duration: task.props ? task.props.visit_duration : this.schedule.visit_duration,
					})
			)
		);
		this.tasks = this.tasks.concat(
			this.visitTasks.map(
				(task) =>
					new TaskObj({
						...task,
						duration: task.props ? task.props.visit_duration : this.schedule.visit_duration,
					})
			)
		);
	}

	prepareVisitTasks() {
		this.visitTasks = new Array();
		this.visits.forEach((visit) => {
			const startStep = this.timeToStep(visit.start_time);
			const endStep = startStep + Number(visit.visit_duration);
			this.visitTasks.push({
				start: startStep,
				end: endStep,
				type: Types.Visit,
				steps: this.stepsInRange(startStep, endStep),
				props: {...visit},
			});
		});
	}

	prepareScheduleTasks() {
		this.scheduleTasks = new Array();
		let currentStep = this.timeToStep(this.schedule.start_time);
		const visitsSteps = this.getUniqueSteps(this.visitTasks);

		while (currentStep + this.schedule.visit_duration <= this.timeToStep(this.schedule.end_time)) {
			const steps = this.stepsInRange(currentStep, currentStep + this.schedule.visit_duration);

			let startStep: number = -1;
			let endStep: number = -1;

			if (steps.length > 0) {
				startStep = steps[0];
				endStep = steps[steps.length - 1];
			}

			if (
				visitsSteps.filter((step) => steps.includes(step) && step !== startStep && step !== endStep).length > 0
			) {
				currentStep += CONSTS.TIME_INTERVAL;
			} else {
				this.scheduleTasks.push({
					start: startStep,
					end: endStep,
					type: Types.Schedule,
					steps: steps,
					props: undefined,
				});

				currentStep += this.schedule.visit_duration;
			}
		}
	}

	prepareAdHockTasks() {
		this.adhockTasks = new Array();
		const visitsSteps = this.getUniqueSteps(this.visitTasks);
		let currentStep = CONSTS.BEGIN_DAY_STEP;

		while (currentStep + this.schedule.visit_duration <= this.timeToStep(this.schedule.start_time)) {
			const steps = this.stepsInRange(currentStep, currentStep + this.schedule.visit_duration);

			let startStep: number = -1;
			let endStep: number = -1;

			if (steps.length > 0) {
				startStep = steps[0];
				endStep = steps[steps.length - 1];
			}

			if (
				visitsSteps.filter((step) => steps.includes(step) && step !== startStep && step !== endStep).length > 0
			) {
				currentStep += CONSTS.TIME_INTERVAL;
			} else {
				this.scheduleTasks.push({
					start: startStep,
					end: endStep,
					type: Types.AdHock,
					steps: steps,
					props: undefined,
				});

				currentStep += this.schedule.visit_duration;
			}
		}

		currentStep = this.timeToStep(this.schedule.end_time);

		while (currentStep < CONSTS.END_DAY_STEP) {
			const steps = this.stepsInRange(currentStep, currentStep + this.schedule.visit_duration);

			let startStep: number = -1;
			let endStep: number = -1;

			if (steps.length > 0) {
				startStep = steps[0];
				endStep = steps[steps.length - 1];
			}

			if (
				visitsSteps.filter((step) => steps.includes(step) && step !== startStep && step !== endStep).length > 0
			) {
				currentStep += CONSTS.TIME_INTERVAL;
			} else {
				this.scheduleTasks.push({
					start: startStep,
					end: endStep,
					type: Types.AdHock,
					steps: steps,
					props: undefined,
				});

				currentStep += this.schedule.visit_duration;
			}
		}
	}

	getUniqueSteps(tasks: IPreTask[]): number[] {
		let visitsStepsTemp = new Array<number>();
		tasks.forEach((task) => {
			visitsStepsTemp = visitsStepsTemp.concat(task.steps);
		});

		return visitsStepsTemp.filter((value, index, self) => self.indexOf(value) === index);
	}

	stepsInRange(start: number, end: number): number[] {
		const steps = new Array<number>();

		for (let i = start; i <= end; i += CONSTS.TIME_INTERVAL) {
			steps.push(i);
		}

		return steps;
	}

	timeToStep(time: string): number {
		const hours = Number(time.substr(0, 2));
		const minutes = Number(time.substr(3, 2));

		if (!isNaN(hours) && !isNaN(minutes)) {
			return hours * 60 + minutes;
		} else {
			return 0;
		}
	}
}

export class TaskObj {
	start: number;
	end: number;
	type: Types;
	props: IVisit | undefined;
	duration: number;

	constructor(task: ITask) {
		this.start = task.start;
		this.end = task.end;
		this.type = task.type;
		this.props = task.props;
		this.duration = task.duration;
	}
}
