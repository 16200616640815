import {Redirect, Route, withRouter} from 'react-router-dom';
import {isToken} from '../../helpers/sessionHelper';

const ProtectedRoute = ({component: Component, isPermitted, ...rest}: any) => {
  // const isPermitted = (): boolean => {
  //   return isPermitted;
  // };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isToken() && (isPermitted || true)) {
          return <Component {...props} />;
        } else {
          return <Redirect to={{pathname: '/login'}} />;
        }
      }}
    />
  );
};

export default withRouter(ProtectedRoute);
