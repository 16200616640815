import React, {useEffect, useState} from 'react';
import {Row, Col, Accordion, Card, Button} from 'react-bootstrap';
import visits from '../../adapters/visit/getVisitsByTreatment';
import IVisit from '../../types/visitInterface';
import AddVisit from '../AddVisit/AddVisit';
import FullVisit from '../FullVisit/FullVisit';

export interface IVisitListProps {
	treatmentId: number;
	patientId: number;
}

const VistiList = (props: IVisitListProps) => {
	const [visitsData, setVisitsData] = useState<IVisit[] | undefined>();
	const [addVisitPopup, setAddVisitPopup] = useState<JSX.Element | undefined>(undefined);

	useEffect(() => {
		if (visitsData === undefined) {
			visits(props.treatmentId)
				.then((response) => {
					setVisitsData(response as IVisit[]);
				})
				.catch((e) => {
					//console.log(e);
				});
		}

		return () => {};
	}, []);

	const handleNewVisit = (visit: IVisit) => {
		if (visitsData) {
			setVisitsData([...visitsData, visit]);
		} else {
			setVisitsData([visit]);
		}
	};

	const handleToggleAddVisit = (close = false) => {
		if (addVisitPopup || close) {
			setAddVisitPopup(undefined);
		} else {
			setAddVisitPopup(
				<AddVisit
					onClick={handleToggleAddVisit}
					userId={0}
					date={new Date()}
					duration={50}
					start={'07:00'}
					isUserSelection={true}
					userPermissions={'admin'}
					treatmentId={props.treatmentId}
					onAdd={handleNewVisit}
					patientId={props.patientId}
				/>
			);
		}
	};

	return (
		<Row>
			<Col sm={12} className='d-flex justify-content-end my-4'>
				<button type='button' className='btn btn-success mx-4' onClick={() => handleToggleAddVisit()}>
					Dodaj wizytę
				</button>
				{addVisitPopup && addVisitPopup}
			</Col>
			<Col>
				<Accordion defaultActiveKey='0'>
					{visitsData &&
						visitsData?.map((visit) => (
							<Card key={visit.id} className='my-2'>
								<div className='w-100 d-flex justify-content-between align-items-center px-3 py-2'>
									<span>
										<b>Wizyta:</b> {visit.date} {visit.start_time} <b>Status:</b>{' '}
										{visit.done ? 'Odbyta' : visit.confirmed ? 'Potwierdzona' : 'Zaplanowana'}
									</span>
									<Accordion.Toggle as={Button} eventKey={visit.id ? visit.id.toString() : ''}>
										Więcej
									</Accordion.Toggle>
								</div>
								<Accordion.Collapse eventKey={visit.id ? visit.id.toString() : ''}>
									<Card.Body className='px-5'>
										{visit.id && (
											<FullVisit
												visitId={visit.id as number}
												start={visit.start_time}
												date={new Date(visit.date)}
												userId={visit.user_id}
												duration={visit.visit_duration}
												userPermissions='admin'
											/>
										)}
									</Card.Body>
								</Accordion.Collapse>
							</Card>
						))}
				</Accordion>
			</Col>
		</Row>
	);
};

export default VistiList;
