import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {Col, Form} from 'react-bootstrap';
import {ISchedule} from '../../../types/SheduleInterface';
// COMPONENTS
import AddPatientForm from '../../Forms/AddPatientForm';
import TimePicker from 'rc-time-picker';
// STYLES
import 'rc-time-picker/assets/index.css';

export interface ISchedulesDayProps {
  schedule: ISchedule;
  onChange: any;
}

const days = ['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'];

const SchedulesDay = (props: ISchedulesDayProps) => {
  const [time, setTime] = useState<{start: string; end: string}>({start: '07:00', end: '15:00'});

  useEffect(() => {
    setTime({start: props.schedule.start_time, end: props.schedule.end_time});

    return () => {};
  }, [props.schedule.end_time, props.schedule.start_time]);

  return (
    <div className='row d-flex w-100 justify-content-between align-items-center my-3'>
      <Col sm={3}>
        <Form.Group controlId='formBasicPassword'>
          <Form.Label>{days[props.schedule.day]}</Form.Label>
          <Form.Switch
            type='switch'
            id='custom-switch'
            checked={props.schedule.avaliable}
            onChange={(event) => props.onChange(event as any)}
            name='avaliable'
            data-day={props.schedule.day}
          />
        </Form.Group>
      </Col>
      <Col sm={3}>
        <Form.Group controlId='formBasicPassword'>
          <Form.Label>Od</Form.Label>
          <TimePicker
            value={moment(time.start.slice(0, 5), 'HH:mm')}
            minuteStep={5}
            onChange={(value) => {
              props.onChange({
                currentTarget: {
                  name: 'start_time',
                  type: 'time',
                  value: value.format('HH:mm'),
                  dataset: {day: props.schedule.day},
                },
              });
            }}
            showSecond={false}
            className='w-100'
            allowEmpty={false}
          />
        </Form.Group>
      </Col>
      <Col sm={3}>
        <Form.Group controlId='formBasicPassword'>
          <Form.Label>Do</Form.Label>
          <TimePicker
            value={moment(time.end.slice(0, 5), 'HH:mm')}
            minuteStep={5}
            onChange={(value) => {
              props.onChange({
                currentTarget: {
                  name: 'end_time',
                  type: 'time',
                  value: value.format('HH:mm'),
                  dataset: {day: props.schedule.day},
                },
              });
            }}
            showSecond={false}
            className='w-100'
            allowEmpty={false}
          />
        </Form.Group>
      </Col>
      <Col sm={3}>
        <Form.Group controlId='formBasicPassword'>
          <Form.Label className='required'>Blok czasowy</Form.Label>
          <Form.Control
            type='number'
            placeholder='50 minut'
            name='visit_duration'
            min='0'
            value={props.schedule.visit_duration}
            onChange={(event) => props.onChange(event as any)}
            data-day={props.schedule.day}
            required
          />
        </Form.Group>
      </Col>
    </div>
  );
};

export default SchedulesDay;
