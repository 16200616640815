import React, {useEffect, useRef} from 'react';
import {BASE_URL} from '../../adapters/xhr';
// COMPONENTS
import {SRLWrapper} from 'simple-react-lightbox';
// STYLES
import './Image.scss';

export interface IImageProps {
  src: string;
  id: number;
  delete?: any;
}

const Image = (props: IImageProps) => {
  const img = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (sessionStorage.getItem('token') !== null) {
      const fetchHeaders = new Headers();
      fetchHeaders.set('Authorization', `Bearer ${sessionStorage.getItem('token')}`);
      fetch(`${BASE_URL}images/${props.src}`, {
        method: 'GET',
        headers: fetchHeaders,
      })
        .then((res) => res.blob())
        .then((result) => {
          if (img.current) {
            img.current.src = URL.createObjectURL(result);
          }
        });
    }
    return () => {};
  }, []);

  const handleDelete = () => {
    props.delete(props.id);
  };

  return (
    <div className='image-box'>
      <SRLWrapper>
        <img src={''} alt={'Loading...'} ref={img} />
      </SRLWrapper>
      <button className='image-box__button' onClick={handleDelete}>
        <i className='bi bi-trash-fill'></i>
      </button>
    </div>
  );
};

export default Image;
